import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdAdd, MdMenu, MdOutlineCreateNewFolder, MdPhone } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete } from "react-icons/md";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(props.data);
  const [ type, setType ] = useState(props.type);

  const [ amount, setAmount ] = useState(0);

  const [ loading, setLoading ] = useState(false);
  
  
  async function init() {
    setReady(true);
  }

  async function withdraw() {
    let url = (type === 'patient') ? 'patient_withdraw_bonus.php' : 'agent_withdraw_bonus.php';

    if(!isNaN(amount) && Number(amount) > 0) {
      setLoading(true);
      await callApi(url, { id: data.id, amount }).then((response) => {
        if(response.status === 1) {
          props.refresher();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError("Invalid amount")
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  useEffect(() => {
    setType(props.type);
  }, [ props.type ]);

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  

  //return render
  if(ready) {
    return (
      <div className="" style={{ width: "100%" }}>

        <h1
          className="mSectionHeader"
        >
          Bonus history & balance
        </h1>

        <div className="row">

          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h6
                  style={{
                    fontSize: "24px",
                  }}
                  className="font-semi-bold"
                >
                  {formatMoney(data.bonusEarned)} Tsh
                </h6>
                <h6>Total Bonus Earned</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-body text-success">
                <h6
                  style={{
                    fontSize: "24px",
                  }}
                  className="font-semi-bold"
                >
                  {formatMoney(data.bonusBalance)} Tsh
                </h6>
                <h6>Available Balance</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-body text-danger">
                <h6
                  style={{
                    fontSize: "24px",
                  }}
                  className="font-semi-bold"
                >
                  {formatMoney(data.bonusEarned - data.bonusBalance)} Tsh
                </h6>
                <h6>Withdrawn Amount</h6>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <h6 className="font-semi-bold">Referrals</h6>
                {
                  (data.bonusHistory && JSON.parse(data.bonusHistory).length > 0) ?
                  JSON.parse(data.bonusHistory).map((item, i) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid black",
                        }}
                        key={i}
                      >
                        <h6
                          className="font-light"
                          style={{
                            fontSize: "24px",
                          }}
                        >
                          {item.patientId}
                        </h6>
                        <h6
                          className=""
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {formatDate(item.date)}
                        </h6>

                        <div className="text-end">
                          <button 
                            className="btn btn-sm btn-outline-dark"
                            onClick={() => {
                              appContext.navTo({
                                item: 'view',
                                subItem: 'patient-info',
                                extraItem: item.patientId
                              })
                            }}
                          >
                            View Patient
                          </button>
                        </div>
                      </div>
                    )
                  })
                  :
                  <h6>No patients were found</h6>
                }
              </div>
            </div>
          </div>


          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h6 className="font-semi-bold">Withdraw</h6>
                <div className="form-group">
                  <label>Amount in Tsh</label>
                  <input
                    className="form-control"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                  />
                </div>

                <button
                  className="btn btn-block btn-padded bg-dark"
                  onClick={() => {
                    appContext.activateDialog({
                      message: "This will process withdraw, continue?",
                      onConfirm: withdraw,
                    })
                  }}
                >
                  Withdraw
                </button>
              </div>
            </div>
            
          </div>


        </div>
        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}

