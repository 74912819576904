import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, formatDateForInput, formatMoney, getInlineLoader } from "../Helpers";
import { MdAdd, MdDelete, MdOutlineCreateNewFolder } from "react-icons/md";

export default function View(props) {
  const appContext = useContext(AppContext);

  

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  
  const prescriptionTemplate = {
    medicineId: "",
    medicineName: "",
    medicineUnit: "",
    medicineUnitPrice: 0,
    routeName: "",
    routeId: "",
    scheduleId: "",
    scheduleName: "",
    duration: "",
    dose: "",
    dispense: "",
    total: "",
    subTotal: 0,
  }

  const [ prescription, setPrescription ] = useState([ prescriptionTemplate ]);
  const [ total, setTotal ] = useState(0);
  const [ recDate, setRecDate ] = useState(formatDateForInput(Date.now()/1000));
  const [ title, setTitle ] = useState("External medical sale");

  function handleMedicineChange(prescriptionItemIndex, medicineIdValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];

      let selectedMedicine = null;

      if(appContext.allMedicineStock) {
        for (const _medicine of appContext.allMedicineStock) {
          if(Number(_medicine.id) === Number(medicineIdValue)) {
            selectedMedicine = _medicine;
            break;
          }
        }
      }

      if(selectedMedicine) {
        _new[prescriptionItemIndex].medicineId = selectedMedicine.id;
        _new[prescriptionItemIndex].medicineName = selectedMedicine.name;
        _new[prescriptionItemIndex].medicineUnit = selectedMedicine.unit;
        _new[prescriptionItemIndex].medicineUnitPrice = selectedMedicine.unitSellingPrice;
      }

      return _new;

    })
  }

  

  function handleMedicineDispenseChange(prescriptionItemIndex, dispenseValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];
      
      _new[prescriptionItemIndex].dispense = (Number(dispenseValue) || 0);

      return _new;

    })
  }

  function handleMedicineTotalChange(prescriptionItemIndex, totalValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];
      
      _new[prescriptionItemIndex].total = (Number(totalValue) || 0);
      _new[prescriptionItemIndex].subTotal = _new[prescriptionItemIndex].total * _new[prescriptionItemIndex].medicineUnitPrice;
      return _new;

    })
  }

  function removePrescriptionItem(prescriptionItemIndex) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];

      _new.splice(prescriptionItemIndex, 1);

      return _new;

    })
  }

  function addPrescriptionItem() {
    setPrescription((_prev) => {
      let _new = [ ..._prev, prescriptionTemplate ];

      return _new;

    })
  }


  async function init () {
    //intialize this view here
    await appContext.getAllMedicineStock();
    setReady(true);
  }

  async function save() {
    if(!loading) {
      if(title.trim().length > 0) {
        if(recDate.trim().length > 0) {
          //..
          if(!isNaN(Number(total)) && Number(total) > 0) {
            setLoading(true);
            await callApi(
              "record_income.php", 
              { 
                title, 
                category: 'pharmacy', 
                recDate, 
                description: JSON.stringify(prescription),
                total,
              }
            ).then(response => {
              if(response.status === 1) {
                //refresh
                appContext.getPharmacySummary();
  
                appContext.tellMessage("Successful");
                appContext.navBack();
              } else {
                appContext.tellError(response.msg);
              }
            })
  
            setLoading(false);
          } else {
            appContext.tellError('Invalid total');
          }
          //..
        } else {
          appContext.tellError("Invalid Record Date");
        }
      } else {
        appContext.tellError("Invalid title");
      }
    } else {
      appContext.tellError('Please wait');
    }
  }
  
  useEffect(() => {
    init();
  }, [])

  

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  useEffect(() => {
    if(prescription) {
      let _total = 0;

      for (const item of prescription) {
        _total = _total + Number(item.subTotal);
      }

      setTotal(Math.ceil(_total));
    }
  }, [ prescription ])
  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Record External Medical Sale</h1>
          </div>
          
          <div className="col-md-6">
            <div className="form-group">
              <label>Record date</label>
              <input
                className="form-control"
                onChange={(e) => {
                  setRecDate(e.target.value)
                }}
                value={recDate}
                type="date"
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">

              <div className="card-body">
                <h6 style={{ fontSize: "18px", marginBottom: "20px" }} className="font-semi-bold card-title">Medicine List</h6>

                {
                  (prescription && prescription.length > 0) ?
                  prescription.map((item, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          width: "100%",
                          padding: "10px",
                          marginBottom: "20px",
                        }}
                        className="mShadow3"
                      >
                        

                        <div className="row">
                          
                          <div className="col-md-6">
                            
                            <h6
                              style={{
                                fontSize: "20px"
                              }}
                              className="font-regular"
                            >
                              {item.medicineName}
                            </h6>
                            <h6
                              style={{
                                fontSize: "14px"
                              }}
                              className="font-semi-bold text-muted"
                            >
                              {
                                (item.medicineUnit) ? <>Tsh {formatMoney(item.medicineUnitPrice)} per {item.medicineUnit}</> : ""
                              }
                            </h6>
                      
                          </div>

                          <div className="col-md-6 text-end">
                            <h6
                              style={{
                                fontSize: "30px"
                              }}
                              className="font-regular"
                            >
                              {formatMoney(item.subTotal)}
                            </h6>
                          </div>

                          <div className="col-8">
                            <h6 className="font-regular" style={{ fontSize: "12px" }}>Select Medicine</h6>
                            <select
                              className="form-control"
                              value={item.medicineId}
                              onChange={(e) => {
                                handleMedicineChange(i, e.target.value)
                              }}
                            >
                              <option></option>
                              {
                                (appContext.allMedicineStock && appContext.allMedicineStock.length > 0) ?
                                appContext.allMedicineStock.map((item2, i2) => {
                                  return (
                                    <option
                                      key={i2}
                                      value={item2.id}
                                    >
                                      {item2.name}
                                    </option>
                                  )
                                })
                                :""
                              }
                            </select>
                          </div>

                          <div className="col-4">
                            <h6 className="font-regular" style={{ fontSize: "12px" }}>Dispense</h6>
                            <input
                              className="form-control"
                              value={item.dispense}
                              onChange={(e) => {
                                handleMedicineDispenseChange(i, e.target.value)
                                handleMedicineTotalChange(i, e.target.value)
                              }}
                            />
                          </div>

                        </div>

                        <div className="text-end">
                          <button
                            className="btn text-secondary"
                            onClick={() => {
                              removePrescriptionItem(i);
                            }}
                          >
                            <MdDelete size={22}/>
                          </button>
                        </div>
                      </div>
                    )
                  })
                  :
                  <h6 className="font-regular" style={{ fontSize: "14px" }}>No medicine was found</h6>
                }

                <div className="text-start">
                  <button
                    className="btn btn-sm bg-dark"
                    onClick={addPrescriptionItem}
                  >
                    <MdAdd size={20}/> Add
                  </button>
                </div>

                <div className="form-group">
                  <label>Total</label>
                  <h6
                    className="font-semi-bold"
                    style={{
                      fontSize: "40px"
                    }}
                  >
                    {formatMoney(total)}
                  </h6>
                </div>
                
              </div>
                
            </div>
          </div>

          
          <div className="col-md-12">
            <button
              className="btn btn-padded btn-block bg-primary"
              onClick={() => {
                appContext.activateDialog({
                  message: "This will create new medical sale record",
                  onConfirm: save,
                })
              }}
            >
              RECORD MEDICAL SALE
            </button>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
