import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, formatDateForInput, formatMoney, getInlineLoader } from "../Helpers";
import { MdAdd, MdDelete, MdOutlineCreateNewFolder } from "react-icons/md";

export default function View(props) {
  const appContext = useContext(AppContext);

  const descriptionTemplate = {
    item: "",
    unit: "",
    unitPrice: 0,
    quantity: 0,
    subTotal: 0,
  };

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ title, setTitle ] = useState("");
  const [ total, setTotal ] = useState(0);
  const [ recDate, setRecDate ] = useState(formatDateForInput(Date.now()/1000));
  const [ category, setCategory ] = useState(props.category || "");

  const [ description, setDescription ] = useState([ descriptionTemplate ]);


  async function init () {
    //intialize this view here
    setReady(true);
  }

  function handleDescriptionItemChange(index, value) {
    setDescription((_prev) => {
      const _new = [ ..._prev ];

      _new[index].item = value;

      return _new;
    })
  }

  function deleteDescriptionItem(index) {
    setDescription((_prev) => {
      const _new = [ ..._prev ];

      _new.splice(index, 1);

      return _new;
    })
  }

  function handleDescriptionUnitChange(index, value) {
    setDescription((_prev) => {
      const _new = [ ..._prev ];

      _new[index].unit = value;
       
      return _new;
    })
  }

  function handleDescriptionUnitPriceChange(index, value) {
    setDescription((_prev) => {
      const _new = [ ..._prev ];

      _new[index].unitPrice = (Number(value) || 0);
      _new[index].subTotal = _new[index].quantity * _new[index].unitPrice;
      return _new;
    })
  }

  function handleDescriptionQuantityChange(index, value) {
    setDescription((_prev) => {
      const _new = [ ..._prev ];

      _new[index].quantity = (Number(value) || 0);
      _new[index].subTotal = _new[index].quantity * _new[index].unitPrice;

      return _new;
    })
  }

  function addDescriptionItem() {
    setDescription((_prev) => {
      const _new = [ ..._prev, descriptionTemplate ];

      return _new;
    })
  }

  async function save() {
    if(!loading) {
      if(title.trim().length > 0) {
        if(recDate.trim().length > 0) {
          //..
          if(!isNaN(Number(total)) && Number(total) > 0) {
            setLoading(true);
            await callApi(
              "record_expense.php", 
              { 
                title, 
                category, 
                recDate, 
                description: JSON.stringify(description),
                total,
              }
            ).then(response => {
              if(response.status === 1) {
                //refresh
                appContext.getPharmacySummary();
                appContext.getLaboratorySummary();
                appContext.getFinancialReportSummary();
  
                appContext.tellMessage("Successful");
                appContext.navBack();
              } else {
                appContext.tellError(response.msg);
              }
            })
  
            setLoading(false);
          } else {
            appContext.tellError('Invalid total');
          }
          //..
        } else {
          appContext.tellError("Invalid Record Date");
        }
      } else {
        appContext.tellError("Invalid title");
      }
    } else {
      appContext.tellError('Please wait');
    }
  }
  
  useEffect(() => {
    init();
  }, [])

  useState(() => {
    setCategory(props.category);
  }, [ props.category ])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  useEffect(() => {
    if(description) {
      let _total = 0;

      for (const item of description) {
        _total = _total + Number(item.subTotal);
      }

      setTotal(Math.ceil(_total));
    }
  }, [ description ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Record Expense</h1>
          </div>

          
          <div className="col-md-12">
            <div className="form-group">
              <label>Title</label>
              <input
                className="form-control"
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
                value={title}
                type="text"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Record date</label>
              <input
                className="form-control"
                onChange={(e) => {
                  setRecDate(e.target.value)
                }}
                value={recDate}
                type="date"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setCategory(e.target.value)
                }}
                value={category}
              >
                <option value=""></option>
                <option value="pharmacy">Pharmacy</option>
                <option value="laboratory">Laboratory</option>
                <option value="others">Others</option>
              </select>
            </div>
          </div>


          <div className="col-md-12">
            <div className="form-group">
              <label>Particulars</label>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Quantity</th>
                    <th>Sub Total</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {
                    (description && description.length > 0) ?
                    description.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <input
                              className="form-control"
                              value={item.item}
                              onChange={(e) => {
                                handleDescriptionItemChange(i, e.target.value)
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              value={item.unit}
                              onChange={(e) => {
                                handleDescriptionUnitChange(i, e.target.value)
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              value={item.unitPrice}
                              onChange={(e) => {
                                handleDescriptionUnitPriceChange(i, e.target.value)
                              }}
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              value={item.quantity}
                              onChange={(e) => {
                                handleDescriptionQuantityChange(i, e.target.value)
                              }}
                            />
                          </td>
                          <td>
                            <span style={{ fontSize: "22px" }}>
                              {formatMoney(item.subTotal)}
                            </span>
                          </td>
                          <td>
                            <MdDelete 
                              size={30}
                              style={{
                                cursor: "pointer",
                              }}
                              className="text-danger"
                              onClick={() => {
                                deleteDescriptionItem(i);
                              }}
                            />
                          </td>
                        </tr>
                      )
                    })
                    :""
                  }
                </tbody>

              </table>
              <div className="text-end">
                <button
                  className="btn btn-sm bg-dark"
                  onClick={addDescriptionItem}
                >
                  <MdAdd size={32}/>
                </button>
              </div>

              <div className="text-start">
                <h6 style={{ fontSize: "34px" }} className="font-light">
                  <span style={{ fontSize: "14px" }}>Total</span><br/>
                  <span className="font-semi-bold">{formatMoney(total)}</span>
                </h6>
              </div>

            </div>
          </div>

          
          <div className="col-md-12">
            <button
              className="btn btn-padded btn-block bg-primary"
              onClick={() => {
                appContext.activateDialog({
                  message: "This will create new Expense record",
                  onConfirm: save,
                })
              }}
            >
              SAVE EXPENSE RECORD
            </button>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
