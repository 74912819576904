import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdAdd, MdMenu, MdOutlineCreateNewFolder, MdPhone } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete } from "react-icons/md";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(props.data);

  const [ chiefComplaint, setChiefComplaint ] = useState("");
  const [ hpi, setHpi ] = useState("");
  const [ generalExamination, setGeneralExamination ] = useState("");
  const [ systemicExamination, setSystemicExamination ] = useState("");
  

  const [ loading, setLoading ] = useState(false);
  
  const testTemplate = {
    testTypeName: "",
    testTypeId: "",
    testResults: "",
    testRemarks: "",
    isTestDone: false,
    isTestAvailable: true,
    isTestReceived: false,
  };

  const diseaseTemplate = {
    diseaseCode: "",
    diseaseName: "",
    diseaseId: "",
  }

  const prescriptionTemplate = {
    medicineId: "",
    medicineName: "",
    medicineUnit: "",
    medicineUnitPrice: 0,
    routeName: "",
    routeId: "",
    scheduleId: "",
    scheduleName: "",
    duration: "",
    dose: "",
    dispense: "",
    total: "",
    subTotal: 0,
  }

  const [ tests, setTests ] = useState([ testTemplate ]);
  const [ finalDiagnosis, setFinalDiagnosis ] = useState([ {...diseaseTemplate} ]);
  const [ preliminalyDiagnosis, setPreliminalyDiagnosis ] = useState([ {...diseaseTemplate} ]);
  const [ finalRemarks, setFinalRemarks ] = useState("");
  const [ prescription, setPrescription ] = useState([ prescriptionTemplate ]);
  const [ attendWithTests, setAttendWithTests ] = useState("yes");
  
  async function init () {
    //initialize this view here
    setReady(false);
    await appContext.getDiagnosticTestTypes();
    await appContext.getAllDiseases();
    await appContext.getAllMedicineStock();
    await appContext.getAllMedicineRoutes();
    await appContext.getAllMedicineSchedules();
    setReady(true);
  }

  function cleanTests() {
    setTests((oldTests) => {
      let newTests = [ ];

      for(let _test of oldTests) {
        if(
          _test.testTypeId && 
          _test.testTypeId.trim().length > 0 && 
          _test.testTypeName && 
          _test.testTypeName.trim().length > 0
        ) {
          newTests.push(_test);
        } 
      }

      return newTests;
    })
  }

  function cleanFinalDiagnosis() {
    setFinalDiagnosis((_prev) => {
      let _new = [ ];

      for(let _disease of _prev) {
        if(
          _disease.diseaseId &&
          _disease.diseaseCode &&
          _disease.diseaseName
        ) {
          _new.push(_disease)
        } 
      }

      return _new;
    })
  }

  function cleanPrescription() {
    setPrescription((_prev) => {
      let _new = [ ];

      for(let _item of _prev) {
        if(
          _item.medicineId &&
          _item.medicineName &&
          _item.medicineUnit &&
          _item.routeId &&
          _item.routeName &&
          _item.scheduleId &&
          _item.scheduleName &&
          _item.duration &&
          _item.dose
        ) {
          _new.push(_item)
        } 
      }

      return _new;
    })
  }

  function handleTestsChange(testIndex, testTypeIdValue) {
    setTests((oldTests) => {
      let newTests = [...oldTests];

      //get testTypeNameValue
      let testTypeNameValue = "";
      for(let _type of appContext.diagnosticTestTypes) {
        if(_type.uid === testTypeIdValue) {
          testTypeNameValue = _type.title;
        }
      }

      newTests[testIndex].testTypeId = testTypeIdValue;
      newTests[testIndex].testTypeName = testTypeNameValue;

      return newTests;
    })
  }

  function deleteTest(testIndex) {
    if(tests && tests.length > 1) {
      setTests((oldTests) => {
        let newTests = [ ...oldTests ];

        newTests.splice(testIndex, 1);
        return newTests;
      })
    } else {
      appContext.tellError("At least one examination is needed");
    }
  }

  function addTest() {
    setTests((oldTests) => {
      let newTests = [ ...oldTests, testTemplate ];

      return newTests;
    })
  }

  function handleFinalDiagnosisChange(diseaseIndex, diseaseIdValue) {
    setFinalDiagnosis((_prev) => {
      let _new = [ ..._prev ];

      let selectedDisease = null;

      if(appContext.allDiseases) {
        for (const _disease of appContext.allDiseases) {
          if(Number(_disease.id) === Number(diseaseIdValue)) {
            selectedDisease = _disease;
            break;
          }
        }
      }

      if(selectedDisease) {
        _new[diseaseIndex].diseaseId = selectedDisease.id;
        _new[diseaseIndex].diseaseName = selectedDisease.name;
        _new[diseaseIndex].diseaseCode = selectedDisease.code;
      }

      return _new;

    })
  }

  function handlePreliminalyDiagnosisChange(diseaseIndex, diseaseIdValue) {
    setPreliminalyDiagnosis((_prev) => {
      let _new = [ ..._prev ];

      let selectedDisease = null;

      if(appContext.allDiseases) {
        for (const _disease of appContext.allDiseases) {
          if(Number(_disease.id) === Number(diseaseIdValue)) {
            selectedDisease = _disease;
            break;
          }
        }
      }

      if(selectedDisease) {
        _new[diseaseIndex].diseaseId = selectedDisease.id;
        _new[diseaseIndex].diseaseName = selectedDisease.name;
        _new[diseaseIndex].diseaseCode = selectedDisease.code;
      }

      return _new;

    })
  }

  function removeDisease(diseaseIndex) {
    setFinalDiagnosis((_prev) => {
      let _new = [ ..._prev ];

      _new.splice(diseaseIndex, 1);

      return _new;

    })
  }

  function addDisease() {
    setFinalDiagnosis((_prev) => {
      let _new = [ ..._prev, diseaseTemplate ];

      return _new;

    })
  }

  function removeDiseasePre(diseaseIndex) {
    setPreliminalyDiagnosis((_prev) => {
      let _new = [ ..._prev ];

      _new.splice(diseaseIndex, 1);

      return _new;

    })
  }

  function addDiseasePre() {
    setPreliminalyDiagnosis((_prev) => {
      let _new = [ ..._prev, diseaseTemplate ];

      return _new;

    })
  }

  function handleMedicineChange(prescriptionItemIndex, medicineIdValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];

      let selectedMedicine = null;

      if(appContext.allMedicineStock) {
        for (const _medicine of appContext.allMedicineStock) {
          if(Number(_medicine.id) === Number(medicineIdValue)) {
            selectedMedicine = _medicine;
            break;
          }
        }
      }

      if(selectedMedicine) {
        _new[prescriptionItemIndex].medicineId = selectedMedicine.id;
        _new[prescriptionItemIndex].medicineName = selectedMedicine.name;
        _new[prescriptionItemIndex].medicineUnit = selectedMedicine.unit;
        _new[prescriptionItemIndex].medicineUnitPrice = selectedMedicine.unitSellingPrice;
      }

      return _new;

    })
  }

  function handleMedicineRouteChange(prescriptionItemIndex, routeIdValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];

      let selectedRoute = null;

      if(appContext.allMedicineRoutes) {
        for (const _route of appContext.allMedicineRoutes) {
          if(Number(_route.id) === Number(routeIdValue)) {
            selectedRoute = _route;
            break;
          }
        }
      }

      if(selectedRoute) {
        _new[prescriptionItemIndex].routeId = selectedRoute.id;
        _new[prescriptionItemIndex].routeName = selectedRoute.name;
      }

      return _new;

    })
  }

  function handleMedicineScheduleChange(prescriptionItemIndex, scheduleIdValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];

      let selectedSchedule = null;

      if(appContext.allMedicineSchedules) {
        for (const _schedule of appContext.allMedicineSchedules) {
          if(Number(_schedule.id) === Number(scheduleIdValue)) {
            selectedSchedule = _schedule;
            break;
          }
        }
      }

      if(selectedSchedule) {
        _new[prescriptionItemIndex].scheduleId = selectedSchedule.id;
        _new[prescriptionItemIndex].scheduleName = selectedSchedule.name;
      }

      return _new;

    })
  }

  function handleMedicineDoseChange(prescriptionItemIndex, doseValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];
      
      _new[prescriptionItemIndex].dose = doseValue;

      return _new;

    })
  }

  function handleMedicineDurationChange(prescriptionItemIndex, durationValue) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];
      
      _new[prescriptionItemIndex].duration = durationValue;

      return _new;

    })
  }

  function removePrescriptionItem(prescriptionItemIndex) {
    setPrescription((_prev) => {
      let _new = [ ..._prev ];

      _new.splice(prescriptionItemIndex, 1);

      return _new;

    })
  }

  function addPrescriptionItem() {
    setPrescription((_prev) => {
      let _new = [ ..._prev, prescriptionTemplate ];

      return _new;

    })
  }

  async function submitAttendWithTests() {
    cleanTests();

    if(chiefComplaint.trim().length > 0) {
      
      if(tests && tests.length > 0) {
        setLoading(true);
        await callApi(
          "submit_attend_with_tests.php", 
          {
            patientId: data.id,
            chiefComplaint,
            hpi,
            generalExamination,
            systemicExamination,
            preliminalyDiagnosis: JSON.stringify(preliminalyDiagnosis),
            tests: JSON.stringify(tests),
          }
        ).then(response => {
          if(response.status === 1) {
            //refresher
            appContext.tellMessage("Successfully saved");
            props.refresher();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError("Invalid examinations or investigations");
      }
      
    } else {
      appContext.tellError("Invalid Chief complaint");
    }
  }

  async function submitAttendWithoutTests() {
    cleanPrescription();
    cleanFinalDiagnosis();

    if(chiefComplaint.trim().length > 0) {
       
      if((finalDiagnosis && finalDiagnosis.length > 0) || finalRemarks.trim().length > 0) {
        if((prescription && prescription.length > 0) || finalRemarks.trim().length > 0) {
          setLoading(true);
          await callApi(
            "submit_attend_without_tests.php", 
            {
              patientId: data.id,
              chiefComplaint,
              hpi,
              generalExamination,
              systemicExamination,
              preliminalyDiagnosis: JSON.stringify(preliminalyDiagnosis),
              prescription: JSON.stringify(prescription),
              finalDiagnosis: JSON.stringify(finalDiagnosis),
              finalRemarks,
            }
          ).then(response => {
            if(response.status === 1) {
              //refresher
              appContext.tellMessage("Successfully saved");
              props.refresher();
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
        } else {
          appContext.tellError("Invalid prescription");
        }
      } else {
        appContext.tellError("Invalid final diagnosis");
      }
      
    } else {
      appContext.tellError("Invalid Chief complaint");
    }
  }

  async function submitFinalDiagnosisAndPrescription() {
    cleanFinalDiagnosis();
    cleanPrescription();

    if((finalDiagnosis && finalDiagnosis.length > 0) || finalRemarks.trim().length > 0) {
      if((prescription && prescription.length > 0) || finalRemarks.trim().length > 0) {
        //..
        setLoading(true);
        await callApi(
          "submit_final_diagnosis_and_prescription.php", 
          {
            patientId: data.id,
            prescription: JSON.stringify(prescription),
            finalDiagnosis: JSON.stringify(finalDiagnosis),
            finalRemarks,
          }
        ).then(response => {
          if(response.status === 1) {
            //refresher
            appContext.tellMessage("Successfully saved");
            props.refresher();
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
        //..
      } else {
        appContext.tellError("Invalid prescription");
      }
    } else {
      appContext.tellError("Invalid final diagnosis");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  const FinalDiagnosisAndPrescription = (
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="row">

            <div className="col-md-12">
              <h6 style={{ fontSize: "20px" }} className="font-light">Final Diagnosis, Prescription & Remarks</h6>
            </div>

            <div className="col-md-12">
              <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Final Remarks</h6>
              <textarea
                className="form-control"
                value={finalRemarks}
                onChange={(e) => setFinalRemarks(e.target.value)}
              ></textarea>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Diseases</h6>

                  {
                    (finalDiagnosis && finalDiagnosis.length > 0) ?
                    finalDiagnosis.map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "100%",
                            padding: "10px",
                            marginBottom: "20px",
                          }}
                          className="mShadow3"
                        >
                          <h6
                            style={{
                              fontSize: "20px"
                            }}
                            className="font-regular"
                          >
                            {item.diseaseName}
                          </h6>
                          <h6
                            style={{
                              fontSize: "20px"
                            }}
                            className="font-semi-bold text-muted"
                          >
                            {item.diseaseCode}
                          </h6>

                          <div className="form-group">
                            <h6 className="font-regular" style={{ fontSize: "12px" }}>Select Disease</h6>
                            <select
                              className="form-control"
                              value={item.diseaseId}
                              onChange={(e) => {
                                handleFinalDiagnosisChange(i, e.target.value)
                              }}
                            >
                              <option></option>
                              {
                                (appContext.allDiseases && appContext.allDiseases.length > 0) ?
                                appContext.allDiseases.map((item2, i2) => {
                                  return (
                                    <option
                                      key={i2}
                                      value={item2.id}
                                    >
                                      {item2.code} - {item2.name}
                                    </option>
                                  )
                                })
                                :""
                              }
                            </select>
                          </div>

                          <div className="text-end">
                            <button
                              className="btn text-secondary"
                              onClick={() => {
                                removeDisease(i);
                              }}
                            >
                              <MdDelete size={22}/>
                            </button>
                          </div>
                        </div>
                      )
                    })
                    :
                    <h6 className="font-regular" style={{ fontSize: "14px" }}>No diseases were found</h6>
                  }

                  <div className="text-start">
                    <button
                      className="btn btn-sm bg-dark"
                      onClick={addDisease}
                    >
                      <MdAdd size={20}/> Add
                    </button>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Prescription</h6>

                  {
                    (prescription && prescription.length > 0) ?
                    prescription.map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "100%",
                            padding: "10px",
                            marginBottom: "20px",
                          }}
                          className="mShadow3"
                        >
                          <h6
                            style={{
                              fontSize: "20px"
                            }}
                            className="font-regular"
                          >
                            {item.medicineName}
                          </h6>
                          <h6
                            style={{
                              fontSize: "14px"
                            }}
                            className="font-semi-bold text-muted"
                          >
                            {item.medicineUnit}
                          </h6>
                          

                          <div className="form-group">
                            <h6 className="font-regular" style={{ fontSize: "12px" }}>Select Medicine</h6>
                            <select
                              className="form-control"
                              value={item.medicineId}
                              onChange={(e) => {
                                handleMedicineChange(i, e.target.value)
                              }}
                            >
                              <option></option>
                              {
                                (appContext.allMedicineStock && appContext.allMedicineStock.length > 0) ?
                                appContext.allMedicineStock.map((item2, i2) => {
                                  return (
                                    <option
                                      key={i2}
                                      value={item2.id}
                                    >
                                      {item2.name}
                                    </option>
                                  )
                                })
                                :""
                              }
                            </select>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Route</h6>
                              <select
                                className="form-control"
                                value={item.routeId}
                                onChange={(e) => {
                                  handleMedicineRouteChange(i, e.target.value)
                                }}
                              >
                                <option></option>
                                {
                                  (appContext.allMedicineRoutes && appContext.allMedicineRoutes.length > 0) ?
                                  appContext.allMedicineRoutes.map((item2, i2) => {
                                    return (
                                      <option
                                        key={i2}
                                        value={item2.id}
                                      >
                                        {item2.name}
                                      </option>
                                    )
                                  })
                                  :""
                                }
                              </select>
                            </div>

                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Schedule/Frequency</h6>
                              <select
                                className="form-control"
                                value={item.scheduleId}
                                onChange={(e) => {
                                  handleMedicineScheduleChange(i, e.target.value)
                                }}
                              >
                                <option></option>
                                {
                                  (appContext.allMedicineSchedules && appContext.allMedicineSchedules.length > 0) ?
                                  appContext.allMedicineSchedules.map((item2, i2) => {
                                    return (
                                      <option
                                        key={i2}
                                        value={item2.id}
                                      >
                                        {item2.name}
                                      </option>
                                    )
                                  })
                                  :""
                                }
                              </select>
                            </div>

                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Dose</h6>
                              <input
                                className="form-control"
                                value={item.dose}
                                onChange={(e) => {
                                  handleMedicineDoseChange(i, e.target.value)
                                }}
                              />
                            </div>

                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Duration</h6>
                              <input
                                className="form-control"
                                value={item.duration}
                                onChange={(e) => {
                                  handleMedicineDurationChange(i, e.target.value)
                                }}
                              />
                            </div>

                          </div>

                          <div className="text-end">
                            <button
                              className="btn text-secondary"
                              onClick={() => {
                                removePrescriptionItem(i);
                              }}
                            >
                              <MdDelete size={22}/>
                            </button>
                          </div>
                        </div>
                      )
                    })
                    :
                    <h6 className="font-regular" style={{ fontSize: "14px" }}>No prescription was found</h6>
                  }

                  <div className="text-start">
                    <button
                      className="btn btn-sm bg-dark"
                      onClick={addPrescriptionItem}
                    >
                      <MdAdd size={20}/> Add
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )

  const FinalDiagnosisAndPrescriptionResults = (
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="row">

            <div className="col-md-12">
              <h6 style={{ fontSize: "20px" }} className="font-light">Final Diagnosis, Prescription & Remarks</h6>
            </div>

            <div className="col-md-12">
              <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Final Remarks</h6>
              {data.finalRemarks || "No remarks"}
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Diseases</h6>

                  {
                    (data.finalDiagnosis && JSON.parse(data.finalDiagnosis).length > 0) ?
                    JSON.parse(data.finalDiagnosis).map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "100%",
                            padding: "10px",
                            marginBottom: "20px",
                          }}
                          className="mShadow3"
                        >
                          <h6
                            style={{
                              fontSize: "20px"
                            }}
                            className="font-regular"
                          >
                            {item.diseaseName}
                          </h6>
                          <h6
                            style={{
                              fontSize: "20px"
                            }}
                            className="font-semi-bold text-muted"
                          >
                            {item.diseaseCode}
                          </h6>

                        </div>
                      )
                    })
                    :
                    <h6 className="font-regular" style={{ fontSize: "14px" }}>No diseases were found</h6>
                  }

                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Prescription</h6>

                  {
                    (data.prescription && JSON.parse(data.prescription).length > 0) ?
                    JSON.parse(data.prescription).map((item, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            width: "100%",
                            padding: "10px",
                            marginBottom: "20px",
                          }}
                          className="mShadow3"
                        >
                          <h6
                            style={{
                              fontSize: "20px"
                            }}
                            className="font-regular"
                          >
                            {item.medicineName}
                          </h6>
                          <h6
                            style={{
                              fontSize: "14px",
                            }}
                            className="font-semi-bold text-muted"
                          >
                            {item.medicineUnit}
                          </h6>
                          

                          <div className="row">
                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Route</h6>
                              <h6
                                style={{
                                  fontSize: "14px"
                                }}
                                className="font-semi-bold"
                              >
                                {item.routeName}
                              </h6>
                            </div>

                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Schedule/Frequency</h6>
                              <h6
                                style={{
                                  fontSize: "14px"
                                }}
                                className="font-semi-bold"
                              >
                                {item.scheduleName}
                              </h6>
                            </div>

                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Dose</h6>
                              <h6
                                style={{
                                  fontSize: "14px"
                                }}
                                className="font-semi-bold"
                              >
                                {item.dose}
                              </h6>
                            </div>

                            <div className="col-6">
                              <h6 className="font-regular" style={{ fontSize: "12px" }}>Duration</h6>
                              <h6
                                style={{
                                  fontSize: "14px"
                                }}
                                className="font-semi-bold"
                              >
                                {item.duration}
                              </h6>
                            </div>

                          </div>

                        </div>
                      )
                    })
                    :
                    <h6 className="font-regular" style={{ fontSize: "14px" }}>No prescription was found</h6>
                  }
                  
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )

  const PrelDiag = (
    <div className="card">
      <div className="card-body">
        <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Diseases</h6>

        {
          (preliminalyDiagnosis && preliminalyDiagnosis.length > 0) ?
          preliminalyDiagnosis.map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "20px",
                }}
                className="mShadow3"
              >
                <h6
                  style={{
                    fontSize: "20px"
                  }}
                  className="font-regular"
                >
                  {item.diseaseName}
                </h6>
                <h6
                  style={{
                    fontSize: "20px"
                  }}
                  className="font-semi-bold text-muted"
                >
                  {item.diseaseCode}
                </h6>

                <div className="form-group">
                  <h6 className="font-regular" style={{ fontSize: "12px" }}>Select Disease</h6>
                  <select
                    className="form-control"
                    value={item.diseaseId}
                    onChange={(e) => {
                      handlePreliminalyDiagnosisChange(i, e.target.value)
                    }}
                  >
                    <option></option>
                    {
                      (appContext.allDiseases && appContext.allDiseases.length > 0) ?
                      appContext.allDiseases.map((item2, i2) => {
                        return (
                          <option
                            key={i2}
                            value={item2.id}
                          >
                            {item2.code} - {item2.name}
                          </option>
                        )
                      })
                      :""
                    }
                  </select>
                </div>

                <div className="text-end">
                  <button
                    className="btn text-secondary"
                    onClick={() => {
                      removeDiseasePre(i);
                    }}
                  >
                    <MdDelete size={22}/>
                  </button>
                </div>
              </div>
            )
          })
          :
          <h6 className="font-regular" style={{ fontSize: "14px" }}>No diseases were found</h6>
        }

        <div className="text-start">
          <button
            className="btn btn-sm bg-dark"
            onClick={addDiseasePre}
          >
            <MdAdd size={20}/> Add
          </button>
        </div>

      </div>
    </div>
  )

  const PrelDiagResults = (
    <div className="card">
      <div className="card-body">
        <h6 style={{ fontSize: "14px" }} className="font-semi-bold card-title">Diseases</h6>

        {
          (data.preliminalyDiagnosis && JSON.parse(data.preliminalyDiagnosis).length > 0) ?
          JSON.parse(data.preliminalyDiagnosis).map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  width: "100%",
                  padding: "10px",
                  marginBottom: "20px",
                }}
                className="mShadow3"
              >
                <h6
                  style={{
                    fontSize: "20px"
                  }}
                  className="font-regular"
                >
                  {item.diseaseName}
                </h6>
                <h6
                  style={{
                    fontSize: "20px"
                  }}
                  className="font-semi-bold text-muted"
                >
                  {item.diseaseCode}
                </h6>

              </div>
            )
          })
          :
          <h6 className="font-regular" style={{ fontSize: "14px" }}>No diseases were found</h6>
        }

      </div>
    </div>
  )

  //return render
  if(ready) {
    return (
      <div className="" style={{ width: "100%" }}>

        <h1
          className="mSectionHeader"
        >
          Doctor's Room Form
        </h1>

        {
          (
            (appContext.userData.role === 'admin' || appContext.userData.role === 'doctor') &&
            data.status === 'unattended' &&
            data.stepAfterReg === 'doctor'
          ) ?
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Chief complaint</label>
                    <textarea
                      className="form-control"
                      cols={4}
                      value={chiefComplaint}
                      onChange={(e) => {
                        setChiefComplaint(e.target.value)
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>HPI</label>
                    <textarea
                      className="form-control"
                      cols={4}
                      value={hpi}
                      onChange={(e) => {
                        setHpi(e.target.value)
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>General Examination</label>
                    <textarea
                      className="form-control"
                      cols={4}
                      value={generalExamination}
                      onChange={(e) => {
                        setGeneralExamination(e.target.value)
                      }}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label>Systemic Examination</label>
                    <textarea
                      className="form-control"
                      cols={4}
                      value={systemicExamination}
                      onChange={(e) => {
                        setSystemicExamination(e.target.value)
                      }}
                    ></textarea>
                  </div>

                </div>

                <div className="col-md-6">
                  
                  <div className="form-group">
                    <label>Preliminaly Diagnosis</label>
                    {PrelDiag}
                  </div>

                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Assign Lab and Imaging Investigation?</label><br/>
                    <button
                      className="btn btn-padded"
                      style={{
                        background: (attendWithTests === 'yes') ? "var(--primaryColor)" : "none", 
                        color: (attendWithTests === 'yes') ? "var(--onPrimaryColor)" : "var(--onBackgroundColor)",
                        fontSize: "16px",
                        margin: "5px",
                      }}
                      onClick={() => {
                        setAttendWithTests('yes')
                      }}
                    >
                      Yes
                    </button>

                    <button
                      className="btn btn-padded"
                      style={{
                        background: (attendWithTests === 'no') ? "var(--primaryColor)" : "none", 
                        color: (attendWithTests === 'no') ? "var(--onPrimaryColor)" : "var(--onBackgroundColor)",
                        fontSize: "16px",
                        margin: "5px",
                      }}
                      onClick={() => {
                        setAttendWithTests('no')
                      }}
                    >
                      No
                    </button>

                  </div>
                </div>

                {
                  (attendWithTests === 'yes') ?
                  <>
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <h6 style={{ fontSize: "14px" }} className="font-semi-bold text-muted">Examinations/Investigation</h6>

                          {
                            (tests && tests.length > 0) ?
                            tests.map((item, i) => {
                              return (
                                <div key={i} className="col-12">
                                  <div
                                    
                                    style={{
                                      width: "100%",
                                      padding: "20px",
                                    }}
                                    className="mShadow3"
                                  >
                                    <div className="form-group">
                                      <h5 className="font-light text-secondary" style={{ fontSize: "32px" }}>{item.testTypeName}</h5>
                                      <label>Select below</label>
                                      <select
                                        className="form-control"
                                        value={item.testTypeId}
                                        onChange={(e) => {
                                          handleTestsChange(i, e.target.value)
                                        }}
                                      >
                                        <option value=""></option>
                                        {
                                          (appContext.diagnosticTestTypes && appContext.diagnosticTestTypes) ?
                                          appContext.diagnosticTestTypes.map((_testType, _i) => {
                                            return (
                                              <option 
                                                key={_i}
                                                value={_testType.uid}
                                              >
                                                {_testType.title}
                                              </option>
                                            )
                                          })
                                          :""
                                        }
                                      </select>
                                    </div>

                                    <div className="text-end">
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          deleteTest(i);
                                        }}
                                      >
                                        <MdDelete className="text-danger" size={20}/>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                            :
                            ""
                          }
                          
                          <div className="text-start">
                            <button
                              className="btn btn-dark btn-padded"
                              onClick={addTest}
                            >
                              Add New Test
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <button
                        className="btn btn-padded bg-primary btn-block"
                        style={{
                          fontSize: "24px"
                        }}
                        onClick={() => {
                          appContext.activateDialog({
                            message: "You can only create this for once. Are you  sure you want to save this form?",
                            onConfirm: submitAttendWithTests,
                          })
                        }}
                      >
                        Save Doctor's Form
                      </button>
                    </div>
                  </>
                  :""
                }

                {
                  (attendWithTests === 'no') ?
                  <>
                    {FinalDiagnosisAndPrescription}
                    <div className="col-md-12">
                      <button
                        className="btn btn-padded bg-primary btn-block"
                        style={{
                          fontSize: "24px"
                        }}
                        onClick={() => {
                          appContext.activateDialog({
                            message: "You can only create this for once. Are you  sure you want to save this form?",
                            onConfirm: submitAttendWithoutTests,
                          })
                        }}
                      >
                        Save Doctor's Form
                      </button>
                    </div>
                  </>
                  :""
                }

              </div>
            </div>
          </div>
          :""
        }

        {
          (
            (appContext.userData.role === 'admin' || appContext.userData.role === 'doctor') &&
            data.status === 'untested' &&
            data.stepAfterReg === 'doctor'
          ) ?
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold text-secondary">
                    Pending Examination/Investigation Results
                  </h6>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Chief complaint</label>
                    <pre className="mInfoPre">
                      {data.chiefComplaint}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>HPI</label>
                    <pre className="mInfoPre">
                      {data.hpi}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>General Examination</label>
                    <pre className="mInfoPre">
                      {data.generalExamination}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>Systemic Examination</label>
                    <pre className="mInfoPre">
                      {data.systemicExamination}
                    </pre>
                  </div>

                </div>

                <div className="col-md-6">
                  
                  <div className="form-group">
                    <label>Preliminaly Diagnosis</label>
                    {PrelDiagResults}
                  </div>

                </div>

              </div>
            </div>
          </div>
          :""
        }

        {
          (
            (appContext.userData.role === 'admin' || appContext.userData.role === 'doctor') &&
            data.status === 'tested' &&
            data.stepAfterReg === 'doctor'
          ) ?
          <div className="card">
            <div className="card-body">
              <div className="row">
                
                <div className="col-md-12">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold text-success">
                    Examination/Investigation Results are Ready
                  </h6>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Chief complaint</label>
                    <pre className="mInfoPre">
                      {data.chiefComplaint}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>HPI</label>
                    <pre className="mInfoPre">
                      {data.hpi}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>General Examination</label>
                    <pre className="mInfoPre">
                      {data.generalExamination}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>Systemic Examination</label>
                    <pre className="mInfoPre">
                      {data.systemicExamination}
                    </pre>
                  </div>

                </div>

                <div className="col-md-6">
                  
                  <div className="form-group">
                    <label>Preliminaly Diagnosis</label>
                    {PrelDiagResults}
                  </div>

                </div>

                {FinalDiagnosisAndPrescription}

                <div className="col-md-12">
                  <button
                    className="btn btn-padded bg-primary btn-block"
                    onClick={() => {
                      appContext.activateDialog({
                        message: "Submit final diagnosis and prescription?",
                        onConfirm: submitFinalDiagnosisAndPrescription
                      })
                    }}
                  >
                    Submit Final Diagnosis and Prescription
                  </button>
                </div>

              </div>
            </div>
          </div>
          :""
        }

        {
          (
            (appContext.userData.role === 'admin' || appContext.userData.role === 'doctor') &&
            data.status === 'diagnosed' &&
            data.stepAfterReg === 'doctor'
          ) ?
          <div className="card">
            <div className="card-body">
              <div className="row">
                
                <div className="col-md-12">
                  <h6 style={{ fontSize: "14px" }} className="font-semi-bold text-success">
                    Diagnosis was completed
                  </h6>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Chief complaint</label>
                    <pre className="mInfoPre">
                      {data.chiefComplaint}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>HPI</label>
                    <pre className="mInfoPre">
                      {data.hpi}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>General Examination</label>
                    <pre className="mInfoPre">
                      {data.generalExamination}
                    </pre>
                  </div>

                  <div className="form-group">
                    <label>Systemic Examination</label>
                    <pre className="mInfoPre">
                      {data.systemicExamination}
                    </pre>
                  </div>

                </div>

                <div className="col-md-6">
                  
                  <div className="form-group">
                    <label>Preliminaly Diagnosis</label>
                    {PrelDiagResults}
                  </div>

                </div>

                {FinalDiagnosisAndPrescriptionResults}

                

              </div>
            </div>
          </div>
          :""
        }
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}

