import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdMenu, MdOutlineCreateNewFolder, MdPhone } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ graphData, setGraphData ] = useState(null)
  
  async function init () {
    //initialize this view here
    //await appContext.getPatientsSummary();
    setReady(true);
  }

  
  useEffect(() => {
    init();
  }, [])
  
  useEffect(() => {
    if(appContext.pharmacySummary) {
      setGraphData([
        { 
          data: [
            appContext.pharmacySummary.today?.sales,
            appContext.pharmacySummary.today?.expenses,
          ],
          label: "Today", 
        }, 
        { 
          data: [
            appContext.pharmacySummary.week?.sales,
            appContext.pharmacySummary.week?.expenses,
          ],
          label: "This Week", 
        }, 
        { 
          data: [
            appContext.pharmacySummary.alltime?.sales,
            appContext.pharmacySummary.alltime?.expenses,
          ],
          label: "All Time", 
        }, 
      ])
    }
  }, [ appContext.pharmacySummary ])

  //return render
  if(ready && appContext.pharmacySummary && graphData) {
    return (
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={graphData}
          >
            <XAxis dataKey="label" />
            
            <Tooltip />
            <Legend />
            <Bar dataKey="data[0]" fill="#28a745" name="Sales" />
            <Bar dataKey="data[1]" fill="#dc3545" name="Expenses" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
