import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, getInlineLoader } from "../Helpers";
import PharmacyHome from '../views/PharmacyHome';
import PharmacyFeed from '../views/PharmacyFeed';

export default function Pharmacy() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ view, setView ] = useState(null);

  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  function getView() {
    if(appContext.navItem === 'pharmacy') {
      if(
        !appContext.navSubItem &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <PharmacyHome/>;
      } else {
        return (
          <PharmacyFeed 
            type={appContext.navSubItem} 
            period={appContext.navExtraItem} 
          />
        )
      }
    }
    
    
    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    let _view = getView();

    if(_view) {
      setView(_view);
    }
  }, [ appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem ])

  //return render
  if(ready) {
    return view;
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
