import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdMenu, MdOutlineCreateNewFolder, MdPhone } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ graphData, setGraphData ] = useState(null)
  
  async function init () {
    //initialize this view here
    //await appContext.getPatientsSummary();
    setReady(true);
  }

  
  useEffect(() => {
    init();
  }, [])
  
  useEffect(() => {
    if(appContext.patientsSummary) {
      setGraphData([
        { 
          data: [
            appContext.patientsSummary.today?.allPatients,
            appContext.patientsSummary.today?.untestedPatients,
            appContext.patientsSummary.today?.testedPatients,
            appContext.patientsSummary.today?.unattendedPatients,
            appContext.patientsSummary.today?.attendedPatients,
            appContext.patientsSummary.today?.diagnosedPatients,
          ],
          label: "Today", 
        }, 
        { 
          data: [
            appContext.patientsSummary.week?.allPatients,
            appContext.patientsSummary.week?.untestedPatients,
            appContext.patientsSummary.week?.testedPatients,
            appContext.patientsSummary.week?.unattendedPatients,
            appContext.patientsSummary.week?.attendedPatients,
            appContext.patientsSummary.week?.diagnosedPatients,
          ],
          label: "This Week", 
        }, 
        { 
          data: [
            appContext.patientsSummary.alltime?.allPatients,
            appContext.patientsSummary.alltime?.untestedPatients,
            appContext.patientsSummary.alltime?.testedPatients,
            appContext.patientsSummary.alltime?.unattendedPatients,
            appContext.patientsSummary.alltime?.attendedPatients,
            appContext.patientsSummary.alltime?.diagnosedPatients,
          ],
          label: "All Time", 
        }, 
      ])
    }
  }, [ appContext.patientsSummary ])

  //return render
  if(ready && appContext.patientsSummary && graphData) {
    return (
      <div style={{ width: '100%', height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={graphData}
          >
            <XAxis dataKey="label" />
            
            <Tooltip />
            <Legend />
            <Bar dataKey="data[0]" fill="var(--primaryColor)" name="All" />
            <Bar dataKey="data[1]" fill="#f5b507" name="Untested" />
            <Bar dataKey="data[2]" fill="#05a849" name="Tested" />
            <Bar dataKey="data[3]" fill="#99061c" name="Unattended" />
            <Bar dataKey="data[4]" fill="#f00c7a" name="Attended" />
            <Bar dataKey="data[5]" fill="#069909" name="Diagnosed" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
