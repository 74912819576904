import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [ name, setName ] = useState("");
  const [ unit, setUnit ] = useState("");
  const [ unitBuyingPrice, setUnitBuyingPrice ] = useState(0);
  const [ unitSellingPrice, setUnitSellingPrice ] = useState(0);
  const [ availableQuantity, setAvailableQuantity ] = useState(0);

  async function init () {
    //intialize this view here
    setReady(true);
  }

  async function create() {
    if(!loading) {
      if(name.trim().length > 0) {
        if(unit.trim().length > 0) {
          if(!isNaN(Number(unitBuyingPrice)) && Number(unitBuyingPrice) > 0) {
            if(!isNaN(Number(unitSellingPrice)) && Number(unitSellingPrice) > 0) {
              if(!isNaN(Number(availableQuantity)) && Number(availableQuantity) > 0) {
                setLoading(true);
                await callApi(
                  "create_medicine_stock.php",
                  {
                    name,
                    unit,
                    unitBuyingPrice,
                    unitSellingPrice,
                    availableQuantity
                  }
                ).then(response => {
                  if(response.status === 1) {
                    appContext.tellMessage("Successful");

                    setName("");
                    setUnit("");
                    setUnitBuyingPrice(0);
                    setUnitSellingPrice(0);
                    setAvailableQuantity(0);
                    
                  } else {
                    appContext.tellError(response.msg);
                  }
                })
                setLoading(false);
              } else {
                appContext.tellError("Invalid available quantity");
              }
            } else {
              appContext.tellError("Invalid unit selling price");
            }
          } else {
            appContext.tellError("Invalid unit buying price");
          }
        } else {
          appContext.tellError("Invalid unit");
        }
      } else {
        appContext.tellError("Invalid name");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Add New Medicine To Stock</h1>
          </div>

          
          <div className="col-md-6">
            <div className="form-group">
              <label>Medicine's Name</label>
              <input
                className="form-control"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Unit</label>
              <input
                className="form-control"
                value={unit}
                onChange={(e) => {
                  setUnit(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Unit Buying Price</label>
              <input
                className="form-control"
                value={unitBuyingPrice}
                onChange={(e) => {
                  setUnitBuyingPrice(e.target.value)
                }}
                type="number"
              />
            </div>

            <div className="form-group">
              <label>Unit Selling Price</label>
              <input
                className="form-control"
                value={unitSellingPrice}
                onChange={(e) => {
                  setUnitSellingPrice(e.target.value)
                }}
                type="number"
              />
            </div>

            <div className="form-group">
              <label>Available Quantity (units)</label>
              <input
                className="form-control"
                value={availableQuantity}
                onChange={(e) => {
                  setAvailableQuantity(e.target.value)
                }}
                type="number"
              />
            </div>

            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `Medicine "${name}" will be added to stock, continue?`,
                      onConfirm: create,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Save" }
              </button>
            </div>
          </div>
          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
