import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, formatDate, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdArrowBack, MdArrowBackIos, MdArrowForwardIos, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineCreateNewFolder, MdSearch } from "react-icons/md";
import { TbArrowForward } from "react-icons/tb";
import { BsForward } from "react-icons/bs";
import OnePatient from '../ones/OnePatient';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ type, setType ] = useState(props.type || 'all'); //all,  tested, untested
  const [ period, setPeriod ] = useState(props.period || 'today'); //today, week, alltime
  const [ filter, setFilter ] = useState("");
  const [ feed, setFeed ] = useState(null);
  
  async function init () {
    //intialize this view here
    await getFeed();
    setReady(true);
  }

  function viewRecord(itemId) {
    if(type === 'sales') {
      appContext.navTo({
        item: 'view',
        subItem: 'income',
        extraItem: itemId,
      })
    } else if(type === 'expenses') {
      appContext.navTo({
        item: 'view',
        subItem: 'expense',
        extraItem: itemId,
      })
    } 
  }

  async function getFeed() {
    await callApi(
      'get_financial_report_feed.php', 
      { 
        type,
        period,
      }).then(response => {
      if(response.status === 1) {
        setFeed(response.data);
      } else {
        appContext.tellError("Network error, could not fetch projects")
      }
    })
  }

  function feedForward() {

  }

  function feedBackward() {
    getFeed();
  }

  useEffect(() => {
    init();
  }, [ type, period ])

  useEffect(() => {
    setType(props.type || 'sales');
    setPeriod(props.period || 'today');
  }, [ props.type, props.period ])


  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">
              Financial Report
            </h1>

            <h6 className="font-bold text-muted" style={{ marginTop: "40px", fontSize: "14px" }}>
              {(type && type.trim().length > 0) ? <span style={{ textTransform: "uppercase" }}>{(type === 'sales') ? "income" : type}</span> : ""}
              {(period && period.trim().length > 0) ? <span style={{ textTransform: "uppercase" }}> <MdArrowForwardIos size={12}/> {period}</span> : ""}
              
            </h6>
          </div>

          <div className="col-md-12">
            <div
              style={{
                maxWidth: "400px",
              }}
              className="d-flex"
            >
              <MdSearch
                size={30}
                className="align-self-center mNoPadding mNoMargin"
                style={{
                  flexShrink: "0"
                }}
              />

              <input
                className="form-control align-self-center"
                placeholder="Filter here by title"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="col-md-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Total Amount (TZS)</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody>
                {
                  (feed && feed.length > 0) ?
                  feed.map((item, i) => {
                    if(filter && filter.trim().length > 0) {
                      if(String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                        return (
                          <tr 
                            key={i}
                            style={{
                              cursor: "pointer",
                            }}

                            onClick={() => {
                              viewRecord(item.id)
                            }}
                          >
                            <td>{item.title}</td>
                            <td>{formatMoney(item.amount)}</td>
                            <td>{formatDate(item.recDate).substring(0, 10)}</td>
                          </tr>
                        )
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <tr 
                          key={i}
                          style={{
                            cursor: "pointer",
                          }}

                          onClick={() => {
                            viewRecord(item.id)
                          }}
                        >
                          <td>{item.title}</td>
                          <td>{formatMoney(item.amount)}</td>
                          <td>{formatDate(item.recDate).substring(0, 10)}</td>
                        </tr>
                      )
                    }
                  })
                  :""
                }
              </tbody>
            </table>
          </div>
          
        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
