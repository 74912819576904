import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import FinancialReportSummaryGraph from '../ones/FinancialReportSummaryGraph';
import { MdSearch } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ filter, setFilter ] = useState('');

  
  async function init () {
    //intialize this view here
    await appContext.getFinancialReportSummary();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])


  //return render
  if(ready && appContext.financialReportSummary) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Financial Reports</h1>
          </div>

          <div className="col-md-12 mNoMargin">
            <FinancialReportSummaryGraph/>
          </div>

          <div className="col-md-12">
            <div className="dropdown">
              <h6 className="font-bold text-muted dropdown-toggle" data-bs-toggle="dropdown" style={{ marginTop: "40px", fontSize: "14px", width: "auto" }}>
                {(appContext.period === 'today') ? "Today" : ""}
                {(appContext.period === 'week') ? "This Week" : ""}
                {(appContext.period === 'alltime') ? "All Time" : ""}
              </h6>
              
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('today')}>Today</a></li>
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('week')}>This Week</a></li>
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('alltime')}>All Time</a></li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'financial',
                  subItem: 'sales',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body text-success">
                <h6 style={{ fontSize: "28px" }}>{formatMoney(appContext.financialReportSummary[appContext.period].sales)}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Overall Income</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'financial',
                  subItem: 'expenses',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body text-danger">
                <h6 style={{ fontSize: "28px" }}>{formatMoney(appContext.financialReportSummary[appContext.period].expenses)}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Overall Expenses</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div 
              className="card  bg-surface"
              style={{

              }}
              
            >
              <div className="card-body text-primary">
                <h6 style={{ fontSize: "28px" }}>{formatMoney(appContext.financialReportSummary[appContext.period].profit)}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Net Profit</h6>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <hr/>
            <div className="row">

              
              {
                (appContext.userData.role === 'admin' || appContext.userData.role === 'accountant') ?
                <div className="col-md-4">
                  <div 
                    className="card bg-surface"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'record-income',
                      })
                    }}
                  >
                    <div className="card-body">
                      <h6 style={{ fontSize: "28px" }}><MdOutlineCreateNewFolder/></h6>
                      <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Record Income</h6>
                    </div>
                  </div>
                </div>
                :
                ""

              }

              {
                (appContext.userData.role === 'admin' || appContext.userData.role === 'accountant') ?
                <div className="col-md-4">
                  <div 
                    className="card bg-surface"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'record-expense',
                      })
                    }}
                  >
                    <div className="card-body">
                      <h6 style={{ fontSize: "28px" }}><MdOutlineCreateNewFolder/></h6>
                      <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Record Expense</h6>
                    </div>
                  </div>
                </div>
                :
                ""

              }

              

            </div>
          </div>

        </div>

        
        

      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
