import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, decodeAge, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { MdAttachFile, MdCheck, MdCheckCircleOutline, MdClose, MdOutlineCreateNewFolder, MdOutlineFrontHand } from "react-icons/md";
import OnePatient from '../ones/OnePatient';
import { BiTimeFive } from "react-icons/bi";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ description, setDescription ] =  useState(null);

  const [ newQuantity, setNewQuantity ] = useState(0);
  
  async function init () {
    //intialize this view here
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi('get_income_data.php', { id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
        
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    if(data) {
      if(data.description) {
        setDescription(JSON.parse(data.description));
      }
    }
  }, [ data ])
  
  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])


  //return render
  if(ready && data) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">

            <h1 className="mSectionHeader font-bold">{data.title}</h1>
            <h6 style={{ fontSize: "14px" }} className="text-success font-bold">Income</h6>
          </div>

          
          <div className="col-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>Tsh {formatMoney(data.amount)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Total Amount</h5>
              </div>
            </div>
          </div>

          

          <div className="col-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.category || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Category</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{formatDate(data.recDate).substring(0, 10)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Date</h5>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold text-muted" style={{ fontSize: "18px" }}>Particulars</h5>

                {
                  //prescription based
                  (
                    description &&
                    description.length > 0 &&
                    description[0].medicineId
                  ) ?
                  <>
                    
                    {
                      (data.patientId) ?
                      <div className="text-end">
                        <button
                          className="btn btn-dark btn-sm"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'patient-info',
                              extraItem: data.patientId,
                            })
                          }}
                        >
                          View Patient
                        </button>
                      </div>
                      :""
                    }

                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Medicine</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th>Sub Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          description.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.medicineName}</td>
                                <td>{item.medicineUnit}</td>
                                <td>{formatMoney(item.medicineUnitPrice)}</td>
                                <td>{item.total}</td>
                                <td>{formatMoney(item.subTotal)}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </>
                  :""
                }

                {
                  //generic
                  (
                    description &&
                    description.length > 0 &&
                    description[0].item
                  ) ?
                  <>
                    
                    {
                      (data.patientId) ?
                      <div className="text-end">
                        <button
                          className="btn btn-dark btn-sm"
                          onClick={() => {
                            appContext.navTo({
                              item: 'view',
                              subItem: 'patient-info',
                              extraItem: data.patientId,
                            })
                          }}
                        >
                          View Patient
                        </button>
                      </div>
                      :""
                    }

                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th>Sub Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          description.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>{item.item}</td>
                                <td>{item.unit}</td>
                                <td>{formatMoney(item.unitPrice)}</td>
                                <td>{item.quantity}</td>
                                <td>{formatMoney(item.subTotal)}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </>
                  :""
                }

              </div>
            </div>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
