import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdMenu, MdOutlineCreateNewFolder, MdOutlineReceipt, MdPhone, MdPrint } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete, MdOutlinePhone, MdOutlineLocationOn } from "react-icons/md";
import logo from '../assets/logo.png';
import { useRef } from "react";
import { useReactToPrint } from 'react-to-print';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(props.data);

  const [ consultationBillTotal, setConsultationBillTotal ] = useState(0);

  const [ loading, setLoading ] = useState(false);
  const receiptRef = useRef();
  
  
  async function init () {
    //initialize this view here
    setReady(true);
  }

  
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  async function confirmConsultationBillPayment() {
    setLoading(true);
    await callApi("confirm_consultation_bill_payment.php", { patientId: data.id }).then(response => {
      if(response.status === 1) {
        appContext.tellMessage("Payment was received")
        props.refresher();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  useEffect(() => {
    if(data) {
      setConsultationBillTotal(data.consultationBill);
    }
  }, [ data ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  //return render
  if(ready) {
    return (
      <div className="" style={{ width: "100%" }}>

        <h1
          className="mSectionHeader"
        >
          Consultation Bill <br/>
          <span 
            style={{ fontSize: "16px", textTransform: "uppercase" }} 
            className={(data.consultationBillStatus === 'paid') ? "font-semi-bold text-success" : "font-semi-bold text-danger" }
          >
            {data.consultationBillStatus}
          </span>

          {
            (data.consultationBillStatus !== 'unavailable') ?
            <div className="text-end">
              {
                (data.consultationBillStatus === 'unpaid') ?
                <button
                  className="btn btn-padded bg-dark"
                  onClick={() => {
                    appContext.activateDialog({
                      message: "This will mark the consultation fee as paid, continue?",
                      onConfirm: confirmConsultationBillPayment,
                    })
                  }}
                  style={{
                    marginRight: "10px"
                  }}
                >
                  Confirm Payment <MdOutlineReceipt size={24}/>
                </button>
                :""
              }

              <button
                className="btn btn-padded bg-dark"
                onClick={handlePrint}
              >
                Print <MdPrint size={24}/>
              </button>

              
            </div>
            :""
          }
        </h1>

        
        {
          (data.consultationBill > 0) ?
          <div className="card" ref={receiptRef}>
            <div className="card-body">

              <div className="row">

                <div className="col-12 mNoMargin" style={{ paddingTop: "40px", }}>
                  <div className="row">

                    <div className="col-4">
                      <img
                        src={logo}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>

                    <div className="col-8">
                      <h1 className="text-secondary font-bold" style={{ fontSize: "40px" }}>{appContext.systemParams.clinicName}</h1>
                      <h6
                        className="font-semi-bold"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <MdOutlineLocationOn size={20} className="text-secondary"/> {appContext.systemParams.address}
                      </h6>
                      <h6
                        className="font-semi-bold"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <MdOutlinePhone size={20} className="text-secondary"/> {appContext.systemParams.phone}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <h1 style={{ fontSize: "24px", marginTop: "20px" }} className="font-semi-bold">Consultation Bill</h1>
                  <h6
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    Status: <span className={(data.consultationBillStatus === 'paid') ? "text-success font-semi-bold" : "text-secondary font-semi-bold"} style={{ fontSize: "18px", textTransform: "uppercase" }}>{data.consultationBillStatus}</span>
                  </h6>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Customer's Name</label>
                    <h6
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      {data.firstname} {data.lastname} 
                    </h6>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Customer's Phone</label>
                    <h6
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      {data.phone}
                    </h6>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Date</label>
                    <h6
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      {formatDate(data.visitDate)}
                    </h6>
                  </div>
                </div>

              </div>

              <hr/>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Unit</th>
                    <th>Unit Price (TZS)</th>
                    <th>Quantity</th>
                    <th>Sub Total (TZS)</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Consultation</td>
                    <td>Session</td>
                    <td>{formatMoney(data.consultationBill)}</td>
                    <td>1</td>
                    <td>{formatMoney(data.consultationBill)}</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <h6
                        style={{
                          fontSize: "22px",
                        }}
                        className="font-light"
                      >
                        <span style={{ fontSize: "30px" }} className="font-semi-bold">{formatMoney(consultationBillTotal)}</span>
                      </h6>
                    </td>
                  </tr>

                </tbody>
              </table>

              
            </div>
          </div>
          :""
        }
        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
