import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdMenu, MdOutlineCreateNewFolder, MdOutlineReceipt, MdPhone, MdPrint } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete, MdOutlinePhone, MdOutlineLocationOn } from "react-icons/md";
import logo from '../assets/logo.png';
import { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { MdAdd } from "react-icons/md";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(props.data);

  const [ edit, setEdit ] = useState(false);

  const [ labBillTotal, setLabBillTotal ] = useState(0);
  const [ medicalBillTotal, setMedicalBillTotal ] = useState(0);

  const testTemplate = {
    testTypeName: "",
    testTypeId: "",
    testResults: "",
    testRemarks: "",
    isTestDone: false,
    isTestAvailable: true,
    isTestReceived: false,
  };

  const [ tests, setTests ] = useState([ testTemplate ]);

  const [ loading, setLoading ] = useState(false);
  const receiptRef = useRef();
  
  
  async function init () {
    //initialize this view here
    setReady(true);
  }
  

  
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  async function confirmLabBillPayment() {
    setLoading(true);
    await callApi("confirm_lab_bill_payment.php", { patientId: data.id }).then(response => {
      if(response.status === 1) {
        appContext.tellMessage("Payment was received")
        props.refresher();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  function cleanTests() {
    setTests((oldTests) => {
      let newTests = [ ];

      for(let _test of oldTests) {
        if(
          _test.testTypeId && 
          _test.testTypeId.trim().length > 0 && 
          _test.testTypeName && 
          _test.testTypeName.trim().length > 0
        ) {
          newTests.push(_test);
        } 
      }


      return newTests;
    })
  }

  async function submitEditedLabBill() {
    cleanTests();
    if(tests && tests.length > 0) {
      setLoading(true);
      await callApi("edit_lab_bill.php", { patientId: data.id, tests: JSON.stringify(tests) }).then(response => {
        if(response.status === 1) {
          appContext.tellMessage("Lab bill was edited successfully")
          props.refresher();
          setEdit(false);
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError("Invalid examinations");
    }
    
  }

  function handleTestsChange(testIndex, testTypeIdValue) {
    setTests((oldTests) => {
      let newTests = [...oldTests];

      //get testTypeNameValue
      let testTypeNameValue = "";
      for(let _type of appContext.diagnosticTestTypes) {
        if(_type.uid === testTypeIdValue) {
          testTypeNameValue = _type.title;
        }
      }

      newTests[testIndex].testTypeId = testTypeIdValue;
      newTests[testIndex].testTypeName = testTypeNameValue;

      return newTests;
    })
  }

  function deleteTest(testIndex) {
    if(tests && tests.length > 1) {
      setTests((oldTests) => {
        let newTests = [ ...oldTests ];

        newTests.splice(testIndex, 1);
        return newTests;
      })
    } else {
      appContext.tellError("At least one examination is needed");
    }
  }

  function addTest() {
    setTests((oldTests) => {
      let newTests = [ ...oldTests, testTemplate ];

      return newTests;
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  useEffect(() => {
    if(data) {
      setTests(JSON.parse(data.testResults))
    }
  }, [ data ])

  useEffect(() => {
    if(data) {
      if(data.labBill) {
        if(JSON.parse(data.labBill).length > 0) {
          const bill = JSON.parse(data.labBill);

          let _total = 0;
          for (const _item of bill) {
            _total = Number(_item.subTotal) + Number(_total);
          }

          setLabBillTotal(Math.ceil(_total));
          
        }
      }
    }
  }, [ data ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  //return render
  if(ready) {
    return (
      <div className="" style={{ width: "100%" }}>

        {
          (edit) ?
          <>
            <h1
              className="mSectionHeader"
            >
              Edit Laboratory Bill <br/>
              

              <div className="text-end">
                <button
                  className="btn btn-padded bg-dark"
                  onClick={submitEditedLabBill}
                >
                  Save Lab Bill Changes
                </button>
              </div>
            </h1>
            
            <div className="row">
                
              {
                (tests && tests.length > 0) ?
                tests.map((item, i) => {
                  return (
                    <div key={i} className="col-12">
                      <div
                        
                        style={{
                          width: "100%",
                          padding: "20px",
                        }}
                        className="mShadow3"
                      >
                        <div className="form-group">
                          <h5 className="font-light text-secondary" style={{ fontSize: "32px" }}>{item.testTypeName}</h5>
                          <label>Select below</label>
                          <select
                            className="form-control"
                            value={item.testTypeId}
                            onChange={(e) => {
                              handleTestsChange(i, e.target.value)
                            }}
                          >
                            <option value=""></option>
                            {
                              (appContext.diagnosticTestTypes && appContext.diagnosticTestTypes) ?
                              appContext.diagnosticTestTypes.map((_testType, _i) => {
                                return (
                                  <option 
                                    key={_i}
                                    value={_testType.uid}
                                  >
                                    {_testType.title}
                                  </option>
                                )
                              })
                              :""
                            }
                          </select>
                        </div>

                        <div className="text-end">
                          <button
                            className="btn"
                            onClick={() => {
                              deleteTest(i);
                            }}
                          >
                            <MdDelete className="text-danger" size={20}/>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })
                :
                ""
              }

              <div className="col-md-12">
                <button
                  className="btn btn-outline-dark"
                  onClick={addTest}
                >
                  <MdAdd size={20}/> Add
                </button>
              </div>
              
            </div>
          </>
          :
          <>
            <h1
              className="mSectionHeader"
            >
              Laboratory Bill <br/>
              <span 
                style={{ fontSize: "16px", textTransform: "uppercase" }} 
                className={(data.labBillStatus === 'paid') ? "font-semi-bold text-success" : "font-semi-bold text-danger" }
              >
                {data.labBillStatus}
              </span>

              {
                (data.labBillStatus !== 'unavailable') ?
                <div className="text-end">
                  {
                    (data.labBillStatus === 'unpaid') ?
                    <>
                      <button
                        className="btn btn-padded bg-dark"
                        onClick={() => {
                          setEdit(true)
                        }}
                        style={{
                          marginRight: "10px"
                        }}
                      >
                        Edit Lab Bill
                      </button>

                      <button
                        className="btn btn-padded bg-dark"
                        onClick={() => {
                          appContext.activateDialog({
                            message: "This will mark the lab bills as paid, continue?",
                            onConfirm: confirmLabBillPayment,
                          })
                        }}
                        style={{
                          marginRight: "10px"
                        }}
                      >
                        Confirm Payment <MdOutlineReceipt size={24}/>
                      </button>
                    </>
                    :""
                  }

                  <button
                    className="btn btn-padded bg-dark"
                    onClick={handlePrint}
                  >
                    Print <MdPrint size={24}/>
                  </button>

                  
                </div>
                :""
              }
            </h1>
            
            {
              (data.labBill && JSON.parse(data.labBill).length > 0) ?
              <div className="card" ref={receiptRef}>
                <div className="card-body">

                  <div className="row">

                    <div className="col-12 mNoMargin" style={{ paddingTop: "40px", }}>
                      <div className="row">

                        <div className="col-4">
                          <img
                            src={logo}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </div>

                        <div className="col-8">
                          <h1 className="text-secondary font-bold" style={{ fontSize: "40px" }}>{appContext.systemParams.clinicName}</h1>
                          <h6
                            className="font-semi-bold"
                            style={{
                              fontSize: "14px"
                            }}
                          >
                            <MdOutlineLocationOn size={20} className="text-secondary"/> {appContext.systemParams.address}
                          </h6>
                          <h6
                            className="font-semi-bold"
                            style={{
                              fontSize: "14px"
                            }}
                          >
                            <MdOutlinePhone size={20} className="text-secondary"/> {appContext.systemParams.phone}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <h1 style={{ fontSize: "24px", marginTop: "20px" }} className="font-semi-bold">Laboratory Bill</h1>
                      <h6
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        Status: <span className={(data.labBillStatus === 'paid') ? "text-success font-semi-bold" : "text-secondary font-semi-bold"} style={{ fontSize: "18px", textTransform: "uppercase" }}>{data.labBillStatus}</span>
                      </h6>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label>Customer's Name</label>
                        <h6
                          style={{
                            fontSize: "20px"
                          }}
                        >
                          {data.firstname} {data.lastname} 
                        </h6>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label>Customer's Phone</label>
                        <h6
                          style={{
                            fontSize: "20px"
                          }}
                        >
                          {data.phone}
                        </h6>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label>Date</label>
                        <h6
                          style={{
                            fontSize: "20px"
                          }}
                        >
                          {formatDate(data.visitDate)}
                        </h6>
                      </div>
                    </div>

                  </div>

                  <hr/>

                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Unit</th>
                        <th>Unit Price (TZS)</th>
                        <th>Quantity</th>
                        <th>Sub Total (TZS)</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        JSON.parse(data.labBill).map((item, i) => {
                          return (
                            <tr
                              key={i}
                            >
                              <td>{item.item}</td>
                              <td>{item.unit}</td>
                              <td>{formatMoney(item.unitPrice)}</td>
                              <td>{item.quantity}</td>
                              <td>{item.subTotal}</td>
                            </tr>
                          )
                        })
                      }

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <h6
                            style={{
                              fontSize: "22px",
                            }}
                            className="font-light"
                          >
                            <span style={{ fontSize: "30px" }} className="font-semi-bold">{formatMoney(labBillTotal)}</span>
                          </h6>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  
                </div>
              </div>
              :""
            }
            
          </>
        }
        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
