import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdMenu, MdOutlineCreateNewFolder, MdOutlineReceipt, MdPhone, MdPrint } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BiTimeFive } from "react-icons/bi";
import { formatDate } from "../Helpers";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDelete, MdOutlinePhone, MdOutlineLocationOn } from "react-icons/md";
import logo from '../assets/logo.png';
import { useRef } from "react";
import { useReactToPrint } from 'react-to-print';

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ data, setData ] = useState(props.data);

  const [ medicalBillTotal, setMedicalBillTotal ] = useState(0);
  const [ medicalBill, setMedicalBill ] = useState(null);

  const [ loading, setLoading ] = useState(false);
  const receiptRef = useRef();
  
  
  async function init () {
    //initialize this view here
    setReady(true);
  }

  
  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  async function confirmMedicalBillPayment() {
    setLoading(true);
    await callApi("confirm_medical_bill_payment.php", { patientId: data.id }).then(response => {
      if(response.status === 1) {
        appContext.tellMessage("Payment was received")
        props.refresher();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  async function createMedicalBill() {
    setLoading(true);
    await callApi("create_medical_bill.php", { patientId: data.id, medicalBill: JSON.stringify(medicalBill) }).then(response => {
      if(response.status === 1) {
        appContext.tellMessage("Medical Bill was create successfully")
        props.refresher();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  function handleMedicalBillDispenseChange(itemIndex, value) {
    setMedicalBill((_prev) => {
      const _new = [ ..._prev ];

      _new[itemIndex].dispense = (Number(value) || 0);

      return _new;
    })
  }

  function handleMedicalBillTotalChange(itemIndex, value) {
    setMedicalBill((_prev) => {
      const _new = [ ..._prev ];

      _new[itemIndex].total = (Number(value) || 0);
      _new[itemIndex].subTotal = _new[itemIndex].total * _new[itemIndex].medicineUnitPrice;

      return _new;
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setData(props.data);
  }, [ props.data ]);

  useEffect(() => {
    if(data) {
      if(data.medicalBillStatus === 'unavailable') {
        setMedicalBill(JSON.parse(data.prescription));
      } else {
        setMedicalBill(JSON.parse(data.medicalBill));
      }
      
    }
  }, [ data ])

  useEffect(() => {
    if(medicalBill) {
      //calculate bill total
      let _total = 0;
      for (const _item of medicalBill) {
        _total = Number(_total) + _item.subTotal; 
      }

      setMedicalBillTotal(_total);
    }
  }, [ medicalBill ])

  useEffect(() => {
    appContext.setShowLoader(loading);
  }, [ loading ])

  //return render
  if(ready) {
    return (
      <div className="" style={{ width: "100%" }}>

        <h1
          className="mSectionHeader"
        >
          Medical Bill <br/>
          <span 
            style={{ fontSize: "16px", textTransform: "uppercase" }} 
            className={(data.medicalBillStatus === 'paid') ? "font-semi-bold text-success" : "font-semi-bold text-danger" }
          >
            {data.medicalBillStatus}
          </span>

          {
            (data.medicalBillStatus !== 'unavailable') ?
            <div className="text-end">
              {
                (data.medicalBillStatus === 'unpaid') ?
                <button
                  className="btn btn-padded bg-dark"
                  onClick={() => {
                    appContext.activateDialog({
                      message: "This will mark the medical bills as paid, continue?",
                      onConfirm: confirmMedicalBillPayment,
                    })
                  }}
                  style={{
                    marginRight: "10px"
                  }}
                >
                  Confirm Payment <MdOutlineReceipt size={24}/>
                </button>
                :""
              }

              <button
                className="btn btn-padded bg-dark"
                onClick={handlePrint}
              >
                Print <MdPrint size={24}/>
              </button>

              
            </div>
            :
            ""
          }
        </h1>

        
        {
          (data.medicalBillStatus === 'unavailable') ?
          <div className="card" ref={receiptRef}>
            <div className="card-body">
              <h6 style={{ fontSize: "18px" }} className="font-light">Create Medical Bill Below</h6>
              <hr/>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Medicine</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Dose</th>
                    <th>Schedule</th>
                    <th>Duration</th>
                    <th>Dispense</th>
                    <th>Sub Total (TZS)</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    (medicalBill && medicalBill.length > 0) ?
                    medicalBill.map((item, i) => {
                      return (
                        <tr
                          key={i}
                        >
                          <td>{item.medicineName}</td>
                          <td>{item.medicineUnit}</td>
                          <td>{formatMoney(item.medicineUnitPrice)}</td>
                          <td>{item.dose}</td>
                          <td>{item.scheduleName}</td>
                          <td>{item.duration}</td>
                          <td>
                            <input
                              className="form-control"
                              value={item.dispense}
                              onChange={(e) => {
                                handleMedicalBillDispenseChange(i, e.target.value)
                                handleMedicalBillTotalChange(i, e.target.value)
                              }}
                            />
                          </td>
                          <td>{formatMoney(item.subTotal)}</td>
                        </tr>
                      )
                    })
                    : ""
                  }

                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <h6
                        style={{
                          fontSize: "22px",
                        }}
                        className="font-light"
                      >
                        <span style={{ fontSize: "30px" }} className="font-semi-bold">{formatMoney(medicalBillTotal)}</span>
                      </h6>
                    </td>
                  </tr>

                </tbody>
              </table>

              <div className="text-end">
                <button
                  className="btn btn-padded bg-dark"
                  onClick={() => {
                    appContext.activateDialog({
                      message: "This will create medical bill for this patient, continue?",
                      onConfirm: createMedicalBill,
                    })
                  }}
                  style={{
                    marginRight: "10px"
                  }}
                >
                  Submit Medical Bill <MdOutlineReceipt size={24}/>
                </button>
                  
              </div>

            </div>
          </div>
          :""
        }

        {
          (data.medicalBillStatus === 'paid' || data.medicalBillStatus === 'unpaid') ?
          <div className="card" ref={receiptRef}>
            <div className="card-body">
              
              <div className="row">

                <div className="col-12 mNoMargin" style={{ paddingTop: "40px", }}>
                  <div className="row">

                    <div className="col-4">
                      <img
                        src={logo}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>

                    <div className="col-8">
                      <h1 className="text-secondary font-bold" style={{ fontSize: "40px" }}>{appContext.systemParams.clinicName}</h1>
                      <h6
                        className="font-semi-bold"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <MdOutlineLocationOn size={20} className="text-secondary"/> {appContext.systemParams.address}
                      </h6>
                      <h6
                        className="font-semi-bold"
                        style={{
                          fontSize: "14px"
                        }}
                      >
                        <MdOutlinePhone size={20} className="text-secondary"/> {appContext.systemParams.phone}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <h1 style={{ fontSize: "24px", marginTop: "20px" }} className="font-semi-bold">Medical Bill</h1>
                  <h6
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    Status: <span className={(data.medicalBillStatus === 'paid') ? "text-success font-semi-bold" : "text-secondary font-semi-bold"} style={{ fontSize: "18px", textTransform: "uppercase" }}>{data.medicalBillStatus}</span>
                  </h6>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Customer's Name</label>
                    <h6
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      {data.firstname} {data.lastname} 
                    </h6>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Customer's Phone</label>
                    <h6
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      {data.phone}
                    </h6>
                  </div>
                </div>

                <div className="col-4">
                  <div className="form-group">
                    <label>Date</label>
                    <h6
                      style={{
                        fontSize: "20px"
                      }}
                    >
                      {formatDate(data.visitDate)}
                    </h6>
                  </div>
                </div>

              </div>

              <hr/>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Medicine</th>
                    <th>Unit</th>
                    <th>Unit Price</th>
                    <th>Total Units</th>
                    <th>Sub Total (TZS)</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    (medicalBill && medicalBill.length > 0) ?
                    medicalBill.map((item, i) => {
                      return (
                        <tr
                          key={i}
                        >
                          <td>{item.medicineName}</td>
                          <td>{item.medicineUnit}</td>
                          <td>{formatMoney(item.medicineUnitPrice)}</td>
                          <td>
                            {item.total}
                          </td>
                          <td>{formatMoney(item.subTotal)}</td>
                        </tr>
                      )
                    })
                    : ""
                  }

                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <h6
                        style={{
                          fontSize: "22px",
                        }}
                        className="font-light"
                      >
                        <span style={{ fontSize: "30px" }} className="font-semi-bold">{formatMoney(medicalBillTotal)}</span>
                      </h6>
                    </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
          :""
        }
        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
