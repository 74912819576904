import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, decodeAge, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { MdAttachFile, MdCheck, MdCheckCircleOutline, MdClose, MdOutlineCreateNewFolder, MdOutlineFrontHand } from "react-icons/md";
import OnePatient from '../ones/OnePatient';
import { BiTimeFive } from "react-icons/bi";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);

  const [ newQuantity, setNewQuantity ] = useState(0);

  //for updates
  const [ title, setTitle ] = useState("");
  const [ purchasingPrice, setPurchasingPrice ] = useState(0);
  const [ operationCost, setOperationCost ] = useState(0);
  const [ sellingPrice, setSellingPrice ] = useState(0);
  const [ resultType, setResultType ] = useState("binary"); //can be binary, numeric or descriptive.
  
  
  async function init () {
    //intialize this view here
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi('get_examination_type_data.php', { id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
        
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function update() {
    if(!loading) {
      if(title.trim().length > 0) {
        if(!isNaN(Number(purchasingPrice)) && Number(purchasingPrice) > 0) {
          if(!isNaN(Number(operationCost)) && Number(operationCost) > 0) {
            if(!isNaN(Number(sellingPrice)) && Number(sellingPrice) > 0) {
              if(resultType.trim().length > 0) {
                //..
                setLoading(true);
                await callApi(
                  'update_diagnostic_test_type.php', 
                  { 
                    id,
                    title, 
                    purchasingPrice, 
                    sellingPrice, 
                    operationCost, 
                    resultType,  
                  }).then((response) => {
                  if(response.status === 1) {
                    //successful
                    getData();
                    appContext.tellMessage("Successful");
                    appContext.getPatientsSummary();
                    appContext.getDiagnosticTestTypes();
                  
                    
                  } else {
                    appContext.tellError(response.msg);
                  }
                })
                setLoading(false);
                //..
              } else {
                appContext.tellError("Invalid test type");
              }
            } else {
              appContext.tellError("Invalid selling price");
            }
          } else {
            appContext.tellError("Invalid operation costs");
          }
        } else {
          appContext.tellError("Invalid Purchasing Price");
        }
      } else {
        appContext.tellError("Invalid examination title");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    if(data) {
      setTitle(data.title);
      setPurchasingPrice(data.purchasingPrice || "0");
      setOperationCost(data.operationCost || "0");
      setSellingPrice(data.sellingPrice || "0");
      setResultType(data.resultType || "descriptive");
    }
  }, [ data ])
  
  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])


  //return render
  if(ready && data) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader font-bold">{data.title}</h1>
            
          </div>

          
          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>Tsh {formatMoney(data.sellingPrice)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Selling Price</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>Tsh {formatMoney(data.purchasingPrice)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Purchasing Price</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>Tsh {formatMoney(data.operationCost)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Operation Cost</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.resultType || "descriptive"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Results Type</h5>
              </div>
            </div>
          </div>

          

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold text-muted" style={{ fontSize: "18px" }}>Update Details</h5>

                <div className="row">
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Title<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value)
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Purchasing Price<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={purchasingPrice}
                        onChange={(e) => {
                          setPurchasingPrice(e.target.value)
                        }}
                        type="number"
                      />
                    </div>

                    <div className="form-group">
                      <label>Operation Costs<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={operationCost}
                        onChange={(e) => {
                          setOperationCost(e.target.value)
                        }}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Selling Price<span className="text-muted">*</span></label>
                      <input
                        className="form-control"
                        value={sellingPrice}
                        onChange={(e) => {
                          setSellingPrice(e.target.value)
                        }}
                        type="number"
                      />
                    </div>

                    <div className="form-group">
                      <label>Results Type<span className="text-muted">*</span></label>
                      <select
                        className="form-control"
                        value={resultType}
                        onChange={(e) => {
                          setResultType(e.target.value)
                        }}
                        type="number"
                      >
                        <option value="binary">Binary</option>
                        <option value="numeric">Numeric</option>
                        <option value="descriptive">Descriptive</option>
                      </select>
                    </div>
                  </div>
                  
                  <div className="col-md-12">
                    
                    <div className="form-group text-end">
                      <button
                        className="btn btn-padded bg-primary mSupportLoading"
                        onClick={() => {
                          if(!loading) {
                            appContext.activateDialog({
                              message: `Examination "${data.title}" will be updated, continue?`,
                              onConfirm: update,
                            })
                          }
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  
                </div>

              </div>
            </div>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
