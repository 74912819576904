import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "../views/Login";
import { callApi, formatMoney, formatMoneyShorten, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import PharmacySummaryGraph from '../ones/PharmacySummaryGraph';
import { MdSearch } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ filter, setFilter ] = useState('');

  
  async function init () {
    //intialize this view here
    await appContext.getPharmacySummary();
    await appContext.getAllMedicineStock();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])


  //return render
  if(ready && appContext.pharmacySummary) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Pharmacy</h1>
          </div>

          <div className="col-md-12 mNoMargin">
            <PharmacySummaryGraph/>
          </div>

          <div className="col-md-12">
            <div className="dropdown">
              <h6 className="font-bold text-muted dropdown-toggle" data-bs-toggle="dropdown" style={{ marginTop: "40px", fontSize: "14px", width: "auto" }}>
                {(appContext.period === 'today') ? "Today" : ""}
                {(appContext.period === 'week') ? "This Week" : ""}
                {(appContext.period === 'alltime') ? "All Time" : ""}
              </h6>
              
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('today')}>Today</a></li>
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('week')}>This Week</a></li>
                <li><a className="dropdown-item" onClick={() => appContext.setPeriod('alltime')}>All Time</a></li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'pharmacy',
                  subItem: 'sales',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body text-success">
                <h6 style={{ fontSize: "28px" }}>{formatMoney(appContext.pharmacySummary[appContext.period].sales)}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Pharmacy Sales</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div 
              className="card bg-surface"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'pharmacy',
                  subItem: 'expenses',
                  extraItem: appContext.period,
                })
              }}
            >
              <div className="card-body text-danger">
                <h6 style={{ fontSize: "28px" }}>{formatMoney(appContext.pharmacySummary[appContext.period].expenses)}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Pharmacy Expenses</h6>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div 
              className="card  bg-surface"
              style={{

              }}
              
            >
              <div className="card-body text-primary">
                <h6 style={{ fontSize: "28px" }}>{formatMoney(appContext.pharmacySummary[appContext.period].profit)}</h6>
                <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Pharmacy Gross Profit</h6>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <hr/>
            <div className="row">

              {
                (appContext.userData.role === 'admin' || appContext.userData.role === 'pharmacist') ?
                <div className="col-md-4">
                  <div 
                    className="card bg-surface"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'create-medicine-stock',
                      })
                    }}
                  >
                    <div className="card-body">
                      <h6 style={{ fontSize: "28px" }}><MdOutlineCreateNewFolder/></h6>
                      <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Record Medicine Stock</h6>
                    </div>
                  </div>
                </div>
                :
                ""
              }

              {
                (appContext.userData.role === 'admin' || appContext.userData.role === 'pharmacist' || appContext.userData.role === 'accountant') ?
                <div className="col-md-4">
                  <div 
                    className="card bg-surface"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'record-pharmacy-expense',
                      })
                    }}
                  >
                    <div className="card-body">
                      <h6 style={{ fontSize: "28px" }}><MdOutlineCreateNewFolder/></h6>
                      <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Record Pharmacy Expense</h6>
                    </div>
                  </div>
                </div>
                :
                ""

              }

              {
                (appContext.userData.role === 'admin' || appContext.userData.role === 'pharmacist' || appContext.userData.role === 'accountant') ?
                <div className="col-md-4">
                  <div 
                    className="card bg-surface"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: 'view',
                        subItem: 'record-external-medical-sale',
                      })
                    }}
                  >
                    <div className="card-body">
                      <h6 style={{ fontSize: "28px" }}><MdOutlineCreateNewFolder/></h6>
                      <h6 style={{ fontSize: "12px" }} className="font-semi-bold">Record External Medical Sale</h6>
                    </div>
                  </div>
                </div>
                :
                ""

              }

            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6 className="font-light" style={{ fontSize: "24px" }}>Medicine Stock</h6>
                
                <div
                  style={{
                    maxWidth: "400px",
                    marginTop: "30px"
                  }}
                  className="d-flex"
                >
                  <MdSearch
                    size={30}
                    className="align-self-center mNoPadding mNoMargin"
                    style={{
                      flexShrink: "0"
                    }}
                  />

                  <input
                    className="form-control align-self-center"
                    placeholder="Filter here by medicine name"
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value)
                    }}
                  />
                </div>

                <hr/>

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Medicine Name</th>
                      <th>Unit</th>
                      <th>Unit Selling Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      (appContext.allMedicineStock && appContext.allMedicineStock.length > 0) ?
                      appContext.allMedicineStock.map((item, i) => {
                        if(filter && filter.trim().length > 0) {
                          if(String(item.name).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                            return (
                              <tr 
                                key={i}
                                style={{
                                  cursor: "pointer",
                                }}

                                onClick={() => {
                                  appContext.navTo({
                                    item: 'view',
                                    subItem: 'medicine-stock',
                                    extraItem: item.id,
                                  })
                                }}
                              >
                                <td>{item.name}</td>
                                <td>{item.unit}</td>
                                <td>{formatMoney(item.unitSellingPrice)}</td>
                              </tr>
                            )
                          } else {
                            return null;
                          }
                        } else {
                          return (
                            <tr 
                              key={i}
                              style={{
                                cursor: "pointer",
                              }}

                              onClick={() => {
                                appContext.navTo({
                                  item: 'view',
                                  subItem: 'medicine-stock',
                                  extraItem: item.id,
                                })
                              }}
                            >
                              <td>{item.name}</td>
                              <td>{item.unit}</td>
                              <td>{formatMoney(item.unitSellingPrice)}</td>
                            </tr>
                          )
                        }
                      })
                      :""
                    }
                  </tbody>
                </table>

              </div>
            </div>
          </div>


        </div>

        
        

      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
