import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ fullname, setFullname ] = useState("");
  const [ phone, setPhone ] = useState("");
  
  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  async function create() {
    if(!loading) {
      if(fullname.trim().length > 0) {
        if(phone.trim().length === 10) {
          //..
          setLoading(true);
          await callApi('create_new_agent.php', { fullname, phone }).then((response) => {
            if(response.status === 1) {
              //successful
              appContext.tellMessage("Successful");
              appContext.getAllAgents();
              
              setFullname("");
              setPhone("");
              
            } else {
              appContext.tellError(response.msg);
            }
          })
          setLoading(false);
          //..
        } else {
          appContext.tellError("Invalid phone number");
        }
        
      } else {
        appContext.tellError("Invalid fullname");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Create New Agent</h1>
          </div>

          
          <div className="col-md-6">
            <div className="form-group">
              <label>Fullname<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={fullname}
                onChange={(e) => {
                  setFullname(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Phone<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={phone}
                placeholder="07XXXXXXXX or 06XXXXXXXX"
                onChange={(e) => {
                  setPhone(e.target.value)
                }}
                maxLength={10}
              />
            </div>  
          </div>
          

          <div className="col-md-12">
            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `Agent "${fullname}" will be created, continue?`,
                      onConfirm: create,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Create Agent" }
              </button>
            </div>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
