import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, getInlineLoader } from "../Helpers";
import { MdOutlineCreateNewFolder } from "react-icons/md";

export default function View() {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  
  const [ title, setTitle ] = useState("");
  const [ purchasingPrice, setPurchasingPrice ] = useState(0);
  const [ operationCost, setOperationCost ] = useState(0);
  const [ sellingPrice, setSellingPrice ] = useState(0);
  const [ resultType, setResultType ] = useState("binary"); //can be binary, numeric or descriptive.
  
  async function init () {
    //intialize this view here
    setReady(true);
  }

  async function create() {
    if(!loading) {
      if(title.trim().length > 0) {
        if(!isNaN(Number(purchasingPrice)) && Number(purchasingPrice) > 0) {
          if(!isNaN(Number(operationCost)) && Number(operationCost) > 0) {
            if(!isNaN(Number(sellingPrice)) && Number(sellingPrice) > 0) {
              if(resultType.trim().length > 0) {
                //..
                setLoading(true);
                await callApi(
                  'create_diagnostic_test_type.php', 
                  { 
                    title, 
                    purchasingPrice, 
                    sellingPrice, 
                    operationCost, 
                    resultType,  
                  }).then((response) => {
                  if(response.status === 1) {
                    //successful
                    appContext.tellMessage("Successful");
                    appContext.getPatientsSummary();
                    appContext.getDiagnosticTestTypes();
                    
                    //reset fields
                    setTitle("");
                    setPurchasingPrice(0);
                    setOperationCost(0);
                    setSellingPrice(0);
                    
                  } else {
                    appContext.tellError(response.msg);
                  }
                })
                setLoading(false);
                //..
              } else {
                appContext.tellError("Invalid test type");
              }
            } else {
              appContext.tellError("Invalid selling price");
            }
          } else {
            appContext.tellError("Invalid operation costs");
          }
        } else {
          appContext.tellError("Invalid Purchasing Price");
        }
      } else {
        appContext.tellError("Invalid examination title");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])

  
  //return render
  if(ready) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader">Create New Examination Type</h1>
          </div>

          
          <div className="col-md-6">
            <div className="form-group">
              <label>Title<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </div>

            <div className="form-group">
              <label>Purchasing Price<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={purchasingPrice}
                onChange={(e) => {
                  setPurchasingPrice(e.target.value)
                }}
                type="number"
              />
            </div>

            <div className="form-group">
              <label>Operation Costs<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={operationCost}
                onChange={(e) => {
                  setOperationCost(e.target.value)
                }}
                type="number"
              />
            </div>

            <div className="form-group">
              <label>Selling Price<span className="text-muted">*</span></label>
              <input
                className="form-control"
                value={sellingPrice}
                onChange={(e) => {
                  setSellingPrice(e.target.value)
                }}
                type="number"
              />
            </div>

            <div className="form-group">
              <label>Results Type<span className="text-muted">*</span></label>
              <select
                className="form-control"
                value={resultType}
                onChange={(e) => {
                  setResultType(e.target.value)
                }}
                type="number"
              >
                <option value="binary">Binary</option>
                <option value="numeric">Numeric</option>
                <option value="descriptive">Descriptive</option>
              </select>
            </div>

            <div className="form-group text-end">
              <button
                className="btn btn-padded bg-primary mSupportLoading"
                onClick={() => {
                  if(!loading) {
                    appContext.activateDialog({
                      message: `Examination "${title}" will be created, continue?`,
                      onConfirm: create,
                    })
                  }
                }}
              >
                {(loading) ? getInlineLoader() : "Create" }
              </button>
            </div>
          </div>
          

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
