import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, decodeAge, formatDate, getInlineLoader } from "../Helpers";
import { MdAttachFile, MdCheck, MdCheckCircleOutline, MdClose, MdOutlineCreateNewFolder, MdOutlineFrontHand, MdOutlineLocationOn, MdOutlinePhone, MdPrint } from "react-icons/md";
import OnePatient from '../ones/OnePatient';
import { BiTimeFive } from "react-icons/bi";
import logo from '../assets/logo.png';
import { useReactToPrint } from 'react-to-print';
import { useRef } from "react";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);
  const [ revisits, setRevisits ] = useState(null)
  const [ initialVisitData, setInitialVisitData ] = useState(null);

  const receiptRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  async function init () {
    //intialize this view here
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi('get_patient_data.php', { id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
        setRevisits(response.revisits);
        setInitialVisitData(response.initialVisitData);
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function markResultsAsPrinted() {
    await callApi('mark_results_as_printed.php', { id }).then(response => {
      //no need
    })
  }


  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])


  //return render
  if(ready && data) {
    return (
      <>
        <div className="" style={{ maxWidth: "1200px", padding: "20px", margin: "0 auto" }}>
          <div className="row">
            <div className="col-md-12 text-end mNoMargin" style={{ paddingTop: "40px" }}>
              <button
                className="btn bg-primary btn-padded"
                onClick={() => {
                  handlePrint();
                  markResultsAsPrinted();
                }}
              >
                <MdPrint size={20}/> Print
              </button>
            </div>
          </div>
        </div>

        <div className="" style={{ maxWidth: "1200px", padding: "20px", margin: "0 auto" }} ref={receiptRef}>
          
          <div className="row">
            
            <div className="col-12 mNoMargin" style={{ paddingTop: "40px", }}>
              <div className="row">

                <div className="col-4">
                  <img
                    src={logo}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>

                <div className="col-8">
                  <h1 className="text-secondary font-bold" style={{ fontSize: "40px" }}>{appContext.systemParams.clinicName}</h1>
                  <h6
                    className="font-semi-bold"
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    <MdOutlineLocationOn size={20} className="text-secondary"/> {appContext.systemParams.address}
                  </h6>
                  <h6
                    className="font-semi-bold"
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    <MdOutlinePhone size={20} className="text-secondary"/> {appContext.systemParams.phone}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div 
                style={{ 
                  width: "100%",
                  padding: "20px",
                  border: "1px solid black", 
                  borderRadius: "10px",
                }}
              >
                <div className="row">

                  <div className="col-6 mNoMargin">
                    
                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>First name</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0", textTransform: "capitalize", }}>{data.firstname}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Last name</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0", textTransform: "capitalize", }}>{data.lastname}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Patient ID</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0" }}>{data.initialVisitId || data.uid}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Visit ID</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0" }}>{data.uid}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Visit Date</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0", }}>{formatDate(data.visitDate)}</h6>
                    </div>
                  </div>

                  <div className="col-6 mNoMargin">
                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Phone</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0" }}>{data.phone}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Gender</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0" }}>{data.gender}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Age</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0" }}>{decodeAge(data.age)}</h6>
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="font-bold" style={{ fontSize: "14px", width: "30%", flexShrink: "0" }}>Date of Birth</h6>
                      <h6 className="font-regular" style={{ fontSize: "14px", width: "70%", flexShrink: "0" }}>{formatDate(data.birthDate).substring(0,10)}</h6>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            
            <div className="col-12">
              <h1 style={{ fontSize: "24px", marginTop: "20px" }} className="font-semi-bold">Examinations Report</h1>
              {
                (data.testResults && JSON.parse(data.testResults).length > 0) ?
                <div className="row">
                  {
                    JSON.parse(data.testResults).map((item, i) => {
                      return (
                        <div
                          className="col-12"
                          key={i}
                        >
                          <div className="card" style={{ border: "1px solid black" }}>
                            <h1 style={{ fontSize: "20px", borderBottom: "1px solid black", padding: "10px" }} className="font-bold text-secondary">{item.testTypeName}</h1>

                            <div className="card-body" style={{ paddingTop: "0px" }}>
                              <div className="form-group" style={{ marginTop: "0px", marginBottom: "0px", paddingBottom: "0px" }}>
                                <label className="font-bold text-muted" style={{ margin: "0" }}>Results/Finding</label>
                                <pre className="mNoMargin" style={{ fontSize: "14px" }}>{item.testResults || 'N/A'}</pre>
                              </div>

                              {
                                (item.testRemarks) ?
                                <div className="form-group" style={{ marginTop: "0px", marginBottom: "0px", paddingBottom: "0px" }}>
                                  <label className="font-bold text-muted" style={{ margin: "0" }}>Remarks</label>
                                  <pre className="mNoMargin" style={{ fontSize: "14px" }}>{item.testRemarks || 'N/A'}</pre>
                                </div>
                                :""
                              }

                              
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                  <div className="col-12" style={{ borderBottom: "1px solid black", paddingTop: "20px", paddingBottom: "20px" }}>
                    <h6 style={{ fontSize: "12px" }} className="font-light mNoMargin"><i>Reported By:</i></h6>
                    {
                      (data.testerData) ?
                      <>
                        <h6 style={{ fontSize: "18px" }} className="font-semi-bold"><i>{data.testerData.fullname}</i></h6>
                        <h6 style={{ fontSize: "14px" }} className="font-regular"><i>{data.testerData.roleDescription}</i></h6>
                      </>
                      :
                      <h6 style={{ fontSize: "18px" }} className="font-semi-bold"><i>N/A</i></h6>
                    }
                  </div>
                </div>
                :
                <h6 className="font-light">No examinations</h6>
              }
              
              
            </div>

            
          </div>

          
        </div>
      </>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
