import { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import Login from "./Login";
import { callApi, decodeAge, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { MdAttachFile, MdCheck, MdCheckCircleOutline, MdClose, MdOutlineCreateNewFolder, MdOutlineFrontHand } from "react-icons/md";
import OnePatient from '../ones/OnePatient';
import { BiTimeFive } from "react-icons/bi";

export default function View(props) {
  const appContext = useContext(AppContext);

  const [ ready, setReady ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ id, setId ] = useState(props.id);
  const [ data, setData ] = useState(null);

  const [ newQuantity, setNewQuantity ] = useState(0);

  //for stock updates
  const [ name, setName ] = useState("");
  const [ unit, setUnit ] = useState("");
  const [ unitBuyingPrice, setUnitBuyingPrice ] = useState(0);
  const [ unitSellingPrice, setUnitSellingPrice ] = useState(0);
  
  async function init () {
    //intialize this view here
    await getData();
    setReady(true);
  }

  async function getData() {
    await callApi('get_medicine_stock_data.php', { id }).then(response => {
      if(response.status === 1) {
        setData(response.data);
        
      } else {
        appContext.tellError(response.msg);
      }
    })
  }

  async function update() {
    if(!loading) {
      if(name.trim().length > 0) {
        if(unit.trim().length > 0) {
          if(!isNaN(Number(unitBuyingPrice)) && Number(unitBuyingPrice) > 0) {
            if(!isNaN(Number(unitSellingPrice)) && Number(unitSellingPrice) > 0) {
              setLoading(true);
              await callApi(
                "update_medicine_stock.php",
                {
                  id,
                  name,
                  unit,
                  unitBuyingPrice,
                  unitSellingPrice,
                }
              ).then(response => {
                if(response.status === 1) {
                  appContext.tellMessage("Changes were saved Successfully"); 
                  getData();        
                  appContext.getAllMedicineStock();  
                } else {
                  appContext.tellError(response.msg);
                }
              })
              setLoading(false);
            } else {
              appContext.tellError("Invalid unit selling price");
            }
          } else {
            appContext.tellError("Invalid unit buying price");
          }
        } else {
          appContext.tellError("Invalid unit");
        }
      } else {
        appContext.tellError("Invalid name");
      }
    } else {
      appContext.tellError("Please wait...");
    }
  }

  async function increaseAvailableQuantity() {
    if(newQuantity && !isNaN(Number(newQuantity)) && Number(newQuantity) > 0) {
      setLoading(true);
      await callApi("increase_medicine_stock_available_quantity.php", { quantity: newQuantity, id: data.id }).then(response => {
        if(response.status === 1) {
          getData();
          appContext.tellMessage("Successful");
          setNewQuantity(0);
        } else {
          appContext.tellError(response.message);
        }
      })
      setLoading(false)
    } else {
      appContext.tellError("Invalid quantity")
    }
  }


  useEffect(() => {
    init();
  }, [ id ])

  useEffect(() => {
    setId(props.id);
  }, [ props.id ])

  useEffect(() => {
    if(data) {
      setName(data.name);
      setUnit(data.unit);
      setUnitBuyingPrice(data.unitBuyingPrice);
      setUnitSellingPrice(data.unitSellingPrice);
    }
  }, [ data ])
  
  useEffect(() =>{
    appContext.setShowLoader(loading);
  }, [ loading ])


  //return render
  if(ready && data) {
    return (
      <div className="container">
        
        <div className="row">
          
          <div className="col-md-12">
            <h1 className="mSectionHeader font-bold">{data.name}</h1>
            
          </div>

          
          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>Tsh {formatMoney(data.unitSellingPrice)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Unit Selling Price</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>Tsh {formatMoney(data.unitBuyingPrice)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Unit Buying Price</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{formatMoney(data.availableQuantity)}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Available Quantity</h5>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold" style={{ fontSize: "18px" }}>{data.unit || "N/A"}</h5>
                <h5 className="font-regular" style={{ fontSize: "12px" }}>Unit</h5>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold text-muted" style={{ fontSize: "18px" }}>Update Available Quantity</h5>

                <div
                  style={{
                    maxWidth: "400px",
                  }}
                >
                  <div className="form-group">
                    <label>Increased Quantity</label>
                    <input
                      value={newQuantity}
                      className="form-control"
                      onChange={(e) => {
                        setNewQuantity(e.target.value)
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-dark btn-padded"
                      onClick={() => {
                        appContext.activateDialog({
                          message: "Update Stock's Available quantity?",
                          onConfirm: increaseAvailableQuantity,
                        })
                      }}
                    >
                      INCREASE
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h5 className="font-semi-bold text-muted" style={{ fontSize: "18px" }}>Update Details</h5>

                <div className="row">
                  
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Medicine's Name</label>
                      <input
                        className="form-control"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value)
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Unit</label>
                      <input
                        className="form-control"
                        value={unit}
                        onChange={(e) => {
                          setUnit(e.target.value)
                        }}
                      />
                    </div>
                  </div>


                  <div className="col-md-6">
                    
                    <div className="form-group">
                      <label>Unit Buying Price</label>
                      <input
                        className="form-control"
                        value={unitBuyingPrice}
                        onChange={(e) => {
                          setUnitBuyingPrice(e.target.value)
                        }}
                        type="number"
                      />
                    </div>

                    <div className="form-group">
                      <label>Unit Selling Price</label>
                      <input
                        className="form-control"
                        value={unitSellingPrice}
                        onChange={(e) => {
                          setUnitSellingPrice(e.target.value)
                        }}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">  
                    <div className="form-group text-end">
                      <button
                        className="btn btn-padded bg-primary mSupportLoading"
                        onClick={() => {
                          if(!loading) {
                            appContext.activateDialog({
                              message: `Medicine "${name}" will be updated, continue?`,
                              onConfirm: update,
                            })
                          }
                        }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                  
                </div>

              </div>
            </div>
          </div>

        </div>

        
      </div>
    )
  } else {
    return (
      <div className="container mSupportLoading">
        {getInlineLoader()}
      </div>
    )
  }

  
}
